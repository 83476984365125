import React from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import {isEmpty} from "../../../utils/validations";
import DLText from "../DLText/DLText";
import appTheme from "../../../assets/appTheme";

const DlTableView = (props) => {

    const {
        tableName, headerData, isRowClickable, pagination, getHeaderStyle, tableData, getCellStyle, tableWidth, tableMaxHeight,
        tableMinHeight, onRowClick, isShowPagination, isDynamicHeight, isShowTotalCount, totalCountRow
    } = props;

    const {totalPages, rowsPerPage, currentPage, onRowPerPageChange, onPageChange, enableNextButton, enablePrevButton} = pagination;

    const getClassName = (index, isRowClickable) => {
        let className = '';
        if (isRowClickable) {
            className += ' cursor'
        }
        return className
    };

    const renderTableHeader = () => {
        if(isEmpty(headerData)){
            return ''
        }
        return (
            <TableHead>
                <TableRow sx={{maxWidth: tableWidth}}>
                    {headerData.map((heading, index) => {
                        const styleConfig = getHeaderStyle(index);
                        return (
                            <TableCell
                                key={index}
                                style={{
                                    ...styleConfig,
                                    zIndex: (index === 0) ? 101 : 100,
                                    left: (index === 0) ? 0 : 'auto',
                                    position: 'sticky',
                                    top: 0
                                }}
                                sortDirection={false}>
                                {heading}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        );
    };

    const getTableMinHeight = (data, minHight) =>{
        if(isEmpty(data) || (data?.length < 6)){
            return 'auto'
        }
        return minHight
    };

    const getTableMaxHeight = (data, maxHeight) =>{
        if(isEmpty(data) || (data?.length < 6)){
            return 'auto'
        }
        return maxHeight
    };

    const renderRows = (tableRow, classes, isLastRow) => {
        let isArray = Array.isArray(tableRow);
        if (isEmpty(tableRow) || !isArray) {
            return ''
        }
        return tableRow?.map((cell, index) => {
            let cellStyle = getCellStyle(index);

            return (
                <TableCell
                    key={index}
                    style={{
                        ...cellStyle,
                        zIndex: (index === 0) ? 100 : 99,
                        left: (index === 0) ? 0 : 'auto',
                        position: (index === 0) ? 'sticky' : 'relative',
                        borderBottom: 'auto',
                        backgroundColor: (index === 0) ? 'white' : 'transparent'
                    }}
                    className={'table-td-mui ' + classes}>
                    {(cell?.isCustomUI) ? cell?.customUI : cell}
                </TableCell>
            )
        })
    };

    const renderTotalRow = (tableRow) => {
        let isArray = Array.isArray(tableRow);
        if (isEmpty(tableRow) || !isArray) {
            return ''
        }
        return tableRow?.map((cell, index) => {
            let cellStyle = getCellStyle(index);

            return (
                <TableCell
                    key={index}
                    style={{
                        ...cellStyle,
                        borderTop: '1px solid var(--gray)',
                        borderBottom: 'none',
                        zIndex: (index === 0) ? 100 : 99,
                        left: (index === 0) ? 0 : 'auto',
                        position: (index === 0) ? 'sticky' : 'relative',
                        backgroundColor: 'white'
                    }}
                    className={'table-td-mui '}>
                    {(cell?.isCustomUI) ? cell?.customUI : cell}
                </TableCell>
            )
        })
    };


    return (
        <div className="w-100 border-radius pagination-container">
           <div className='table-border border-radius'>
               <Box className='my-1'>
                   {!isEmpty(tableName) && <div className='px-3 py-3'>
                       <DLText id={''}
                               text={tableName}
                               fontSize={"lg"}
                               fontWeight={"semi-bold"}
                       />
                   </div>}
                   <Paper sx={{boxShadow: "none"}}>
                       <TableContainer
                           style={{
                               minHeight: isDynamicHeight ? getTableMinHeight(tableData, tableMinHeight) : tableMinHeight,
                               maxHeight: isDynamicHeight ? getTableMaxHeight(tableData, tableMaxHeight) : tableMaxHeight,
                               width: tableWidth}}>
                           {
                               <Table aria-labelledby="tableTitle"
                                      stickyHeader>
                                   {renderTableHeader()}
                                   <TableBody>
                                       {tableData.map((tableRow, index) => {
                                           let classes = getClassName(index, isRowClickable);
                                           let isLastRow = (tableData?.length === index+1) && (tableData?.length !== 1);

                                           return (
                                               <TableRow
                                                   role="checkbox"
                                                   tabIndex={-1}
                                                   key={index}
                                                   onClick={() => {
                                                       isRowClickable ? onRowClick(tableRow?.id) : ''
                                                   }}
                                                   className={classes}
                                                   sx={{
                                                       border: 'none',
                                                       '&:hover > .table-td-mui': {
                                                           backgroundColor: 'var(--blue-light) !important',
                                                       },
                                                   }}>
                                                   {renderRows(tableRow?.data, classes, isLastRow)}
                                               </TableRow>);
                                       })}
                                   </TableBody>
                                   {isShowTotalCount && <TableBody
                                       style={{
                                           position:'sticky',
                                           bottom:0,
                                           zIndex:999
                                       }}>
                                       <TableRow
                                           ole="checkbox"
                                           tabIndex={-1}
                                           sx={{
                                               border: 'none',
                                               backgroundColor: 'white',
                                               '& > .table-td-mui': {
                                                   backgroundColor: 'var(--white) !important',
                                               },
                                           }}>
                                           {renderTotalRow(totalCountRow)}
                                       </TableRow>
                                   </TableBody>}
                               </Table>
                           }
                       </TableContainer>
                       {isShowPagination && <TablePagination
                           rowsPerPageOptions={[10, 25, 50, 100]}
                           component="div"
                           count={totalPages}
                           rowsPerPage={rowsPerPage}
                           page={currentPage}
                           onPageChange={onPageChange}
                           onRowsPerPageChange={onRowPerPageChange}
                           nextIconButtonProps={{disabled: enableNextButton}}
                           backIconButtonProps={{disabled: enablePrevButton}}
                           sx={{ borderTop: '1.5px solid '+ appTheme.appColor.grayLight}}
                       />}
                   </Paper>
               </Box>
           </div>
        </div>
    );
};

export default DlTableView;