import React from 'react';

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import {isEmpty} from "../../../../../../utils/validations";
import appTheme from "../../../../../../assets/appTheme";
import {getTimeFromNowWithDate} from "../../../../../../utils/helper";


const NSEResponseErrorView = (props) => {

    const {
        history, isOpenNSEErrorModal, txnNSEErrors, handleCloseNSEErrorsModal, updatedAt
    } = props;

    const renderStatusIcon = (status) => {
        if (isEmpty(status)) {
            return ''
        }
        switch (status) {
            case 'FAILED':
                return <i className="fa-regular fa-circle-xmark NSE-status-icon"
                          style={{color: appTheme.appColor.danger}}/>;
            case 'SUCCESS':
                return <i className="fa-regular fa-circle-check NSE-status-icon"
                          style={{color: appTheme.appColor.success}}/>;

            default:
                return '';
        }
    };


    const renderErrors = () => {
        if (isEmpty(txnNSEErrors)) {
            return ''
        }
        return txnNSEErrors.map((item, index) => {
            return (
                <div key={'NSE-status' + index}>
                    <div className={'error'}>
                        <DLText id={''}
                                text={item?.errorDetails?.message}
                                fontSize={"xs"}
                                fontColor={"danger"}
                        />
                    </div>
                </div>
            )
        });
    };

    const renderButtons = () => {
        return (
            <div>
                <DLButton
                    id={'cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseNSEErrorsModal()}/>
            </div>
        )
    };

    const renderApproveTransactionModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseNSEErrorsModal()}
                    open={isOpenNSEErrorModal}
                    id={'discard-modal'}>
                    <DLModalTitle
                        id={'discard-title'}
                        onClose={() => handleCloseNSEErrorsModal()}
                        title={'NSE Status'}/>
                    <DLModalContainer
                        id={'discard-container'}>
                        <div className='py-3'
                             style={{minHeight: (isEmpty(txnNSEErrors)) ? 100 : (txnNSEErrors?.length > 5) ? 300 : ((txnNSEErrors?.length) * 50),
                                 maxHeight: 'calc(100% - 64px)'}}>
                            {isEmpty(txnNSEErrors) ?
                                <div className='d-flex align-items-center justify-content-center my-2'>
                                    No Data Avaliable
                                </div> :
                                <div key={'NSE-status'}>
                                    <div className='d-flex'>
                                        <div>
                                            {renderStatusIcon('FAILED')}
                                            <div className={(txnNSEErrors?.length > 5) ? 'd-flex justify-content-center h-100' : 'd-flex justify-content-center h-75'}>
                                                <div className='step-connector'/>
                                            </div>
                                        </div>

                                        <div>
                                            <div>
                                                <DLText id={''}
                                                        text={
                                                            <div style={{lineHeight: '15px'}}>
                                                                {'Failed'}
                                                                <span className='ps-1'
                                                                      style={{
                                                                          fontSize: 'var(--font-size-xxs)',
                                                                          color: 'var(--gray-dark)'
                                                                      }}>{getTimeFromNowWithDate(updatedAt)}
                                                                </span>
                                                            </div>
                                                        }
                                                        fontWeight={"semi-bold"}
                                                        isInline={true}
                                                />
                                            </div>
                                            {renderErrors()}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </DLModalContainer>
                </DLModal>
            </div>
        );
    };

    const overlayApproveTransaction = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'discard-text'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'Confirmation'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseNSEErrorsModal()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        {renderModalError()}
                        <div className='my-4'>
                            {renderUiText()}
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        {renderButtons()}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {props.isOverlay ? overlayApproveTransaction() : renderApproveTransactionModal()}
        </div>
    );
};

export default NSEResponseErrorView;