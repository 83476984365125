import React from 'react';

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import appTheme from "../../../../../assets/appTheme";

import DLText from "../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DiscardTransaction from "./../components/discardTransaction/DiscardTransaction";
import SendToManagement from "./../components/sendToManagement/SendToManagement";
import EditPaymentDetails from "./../components/editPaymentDetails/EditPaymentDetails";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu/Menu";
import {getTicketBadge, renderStatusUI} from "../../../FTHelper";
import MoveToDraft from "../components/moveToDraft/MoveToDraft";
import {getRoundedvalue, isCorporateUser, numberWithCommas} from "../../../../../utils/helper";
import moment from "moment";
import ResendEmailToClient from "../components/resendEmailToClient/ResendEmailToClient";
import DLMenuOptions from "../../../../../core/components/DLMenuOptions/DLMenuOptions";
import CopyTransaction from "../components/copyTransaction/CopyTransaction";
import NSEResponseErrorModal from "../components/NSEResponseErrorModal/NSEResponseError";

const FTDetailsForCRMView = (props) => {
    const {
        isMobileView,
        category,
        IINNumber,
        accountType,
        isShowLoader
        ,
        UIState,
        paymentDetails,
        investmentDetails,
        ticketNumber,
        txnStatus,
        txnStage,
        txnData,
        handleOpenDiscardTransactionModal,
        handleOpenSendToManagementModal,
        handleOpenEditPaymentModal,
        handleAddOrEditTransactionDetails,
        anchorEl,
        handleItemClose,
        handleItemOpen,
        paymentId,
        FTStatuses,
        handleOpenMoveToDraftModal,
        rejectComment,
        getDepositMode,
        getBankName,
        responseError,
        getPaymentMode,
        handleCloseNSEErrorsModal,
        handleOpenNSEErrorsModal,
        isOpenNSEErrorModal,
        txnNSEErrors,
        updatedAt
    } = props;

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    }

    const renderNSEErrors = () =>{
        return(
            <NSEResponseErrorModal
                {...props}
                isOpenNSEErrorModal = {isOpenNSEErrorModal}
                txnNSEErrors={txnNSEErrors}
                updatedAt={updatedAt}
                handleCloseNSEErrorsModal={()=>{handleCloseNSEErrorsModal()}}
            />
        )
    };
    const renderCopyTransactionModal = () => {
        const {isMobileView, handleCloseCopyTransactionModal, isCopyTransactionModalOpen} = props;
        if (isCopyTransactionModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseCopyTransactionModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <CopyTransaction {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <CopyTransaction {...props}/>
                )
            }
        }

    };

    const renderMenuOptions = () => {
        const {handleOpenCopyTransactionModal} = props;
        return (
            <div className="d-flex justify-content-around">
                <div className="px-3" style={{fontSize: appTheme.fontSizes.md}}>
                    <DLMenuOptions
                        id={"menuOption"}
                        onSelect={(value) =>
                            handleOpenCopyTransactionModal(value)
                        }
                        options={[{
                            label: "Copy Transaction",
                            value: "COPY_TRANSACTION"
                        }]}
                    />
                </div>
            </div>
        )
    }

    const renderMoveToDraftModal = () => {
        const {isMobileView, handleCloseMoveToDraftModal, isMoveToDraftModalOpen} = props;
        if (isMoveToDraftModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseMoveToDraftModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <MoveToDraft {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <MoveToDraft {...props}/>
                )
            }
        }

    };

    const renderResendModal = () => {
        const {isMobileView, handleCloseResendModal, isResendModalOpen} = props;
        if (isResendModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseResendModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <ResendEmailToClient {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <ResendEmailToClient {...props}/>
                )
            }
        }

    };

    const renderDiscardTransactionModal = () => {
        const {isMobileView, handleCloseDiscardTransactionModal, isDiscardTransactionModalOpen} = props;
        if (isDiscardTransactionModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}}
                            onClick={handleCloseDiscardTransactionModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <DiscardTransaction {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <DiscardTransaction {...props}/>
                )
            }
        }

    };

    const renderEditPaymentModal = () => {
        const {isEditPaymentModalOpen} = props;
        if (isEditPaymentModalOpen) {
            return (
                <EditPaymentDetails {...props}/>
            )
        }

    };

    const renderSendToManagementTransactionModal = () => {
        const {isMobileView, handleCloseSendToManagementModal, isSendToManagementModalOpen} = props;

        if (isSendToManagementModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseSendToManagementModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <SendToManagement {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <SendToManagement {...props}/>
                )
            }
        }

    };

    const renderAccountHoldersDetails = (accountDetails) => {
        const {details, corporateAccountDetails} = props;
        let accountType = isEmpty(details?.accountDetails) ? "" : details?.accountDetails[0]?.accountType;
        if (isCorporateUser(accountType)) {
            let name = corporateAccountDetails?.name;
            let email = corporateAccountDetails?.email;
            let phoneNumber = corporateAccountDetails?.phoneNumber;
            return (
                <div>
                    <MenuItem
                        className={'py-0 text-center pb-2 pt-2'}
                        style={{minWidth: 200, height: "max-content", cursor: "auto"}}
                        disableRipple
                    >
                        <div className='text-start'>
                            <DLText
                                id={'account-holder-name'}
                                text={'Account Holder: ' + name}
                                marginBottom={"none"}
                                fontSize={"xs"}
                            />
                            <div>
                                {isEmpty(email) ? '-' : <a href={'mailto:' + email}
                                                           style={{
                                                               fontSize: 'var(--font-size-xs)',
                                                               fontFamily: appTheme.default.fontFamily
                                                           }}>
                                    {email}
                                </a>}
                            </div>
                            <div>
                                {isEmpty(phoneNumber) ? '-' : <a href={'tel:' + phoneNumber}
                                                                 style={{
                                                                     fontSize: 'var(--font-size-xs)',
                                                                     fontFamily: appTheme.default.fontFamily
                                                                 }}>
                                    {phoneNumber}
                                </a>}
                            </div>
                        </div>
                    </MenuItem>
                </div>
            )
        }
        return accountDetails.map((accountHolder, index) => (
            <div key={'menuItem-' + accountHolder.id}>
                <MenuItem
                    className={'py-0 text-center pb-2 pt-2'}
                    style={{minWidth: 200, height: "max-content", cursor: "auto"}}
                    disableRipple
                >
                    <div className='text-start'>
                        <DLText
                            id={'account-holder-name'}
                            text={'Account Holder ' + (index + 1) + ': ' + accountHolder.fullName}
                            marginBottom={"none"}
                            fontSize={"xs"}
                        />
                        <div>
                            {isEmpty(accountHolder.email) ? '-' : <a href={'mailto:' + accountHolder.email}
                                                                     style={{
                                                                         fontSize: 'var(--font-size-xs)',
                                                                         fontFamily: appTheme.default.fontFamily
                                                                     }}>
                                {accountHolder.email}
                            </a>}
                        </div>
                        <div>
                            {isEmpty(accountHolder.phoneNumber) ? '-' : <a href={'tel:' + accountHolder.phoneNumber}
                                                                           style={{
                                                                               fontSize: 'var(--font-size-xs)',
                                                                               fontFamily: appTheme.default.fontFamily
                                                                           }}>
                                {accountHolder.phoneNumber}
                            </a>}
                        </div>
                    </div>
                </MenuItem>
                <div className={'px-3'}>
                    {index < accountDetails.length - 1 && (
                        <div className='border-bottom'/>
                    )}
                </div>
            </div>
        ));
    };

    const renderAccountDetails = () => {
        const {accountDetails, corporateAccountDetails, details, getAccountHolderDetails} = props;

        let allAccountHoldersNames = "";
        let count = "";
        let accountType = isEmpty(details?.accountDetails) ? "" : details?.accountDetails[0]?.accountType;
        let accountHolderDetails = getAccountHolderDetails(accountDetails, accountType);

        if (!isEmpty(accountHolderDetails)) {
            accountHolderDetails.map((applicant, index) => {
                if (isEmpty(allAccountHoldersNames)) {
                    allAccountHoldersNames = applicant?.fullName;
                } else {
                    count = index;
                }
            });
            if (!isEmpty(count)) {
                allAccountHoldersNames = allAccountHoldersNames + ' + ' + count;
            }
        }
        if (isMobileView) {
            allAccountHoldersNames = details.accountDetails[0]?.accountHolders?.length
        }

        if (isCorporateUser(accountType)) {
            allAccountHoldersNames = corporateAccountDetails?.name;
            if (isMobileView) {
                allAccountHoldersNames = !isEmpty(allAccountHoldersNames) ? 1 : 0;
            }
        }

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    {
                        <div onClick={(event) => {
                            handleItemOpen(event)
                        }}>
                            <DLText id={'account-holder-name'}
                                    text={allAccountHoldersNames}
                                    marginBottom={"none"}
                                    isClickable={true}
                                    fontColor={"darkBlue"}
                            />
                        </div>
                    }
                    <Menu id="items-menu"
                          anchorEl={anchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                          transformOrigin={{vertical: "top", horizontal: "left"}}
                          open={Boolean(anchorEl)}
                          onClose={() => handleItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '0px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)',
                                  borderRadius: '8px'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center',
                                  borderRadius: '8px'
                              },
                          }}>
                        {accountHolderDetails && accountHolderDetails.length > 0 || !isEmpty(corporateAccountDetails) ? (
                            renderAccountHoldersDetails(accountHolderDetails)
                        ) : (
                            <MenuItem disabled>No applicants available</MenuItem>
                        )}
                    </Menu>
                </div>
            </div>
        );
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            <div className="d-flex">
                                <div className='d-flex align-items-center'>
                                    Financial Transaction: Lumpsum Purchase
                                </div>
                                {getTicketBadge("Ticket No: ", ticketNumber)}
                            </div>
                        </div>

                    </div>
                    {/*<div className='d-flex justify-content-center align-items-center'>*/}
                    {/*    {renderMenuOptions()}*/}
                    {/*</div>*/}

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        <DLText id={'category'}
                                text={category}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        {renderAccountDetails()}
                    </div>
                    <div className={(txnStage === 'NSE_SUBMISSION' && txnStatus === 'FAILED') ? 'px-2 border-end-black' : 'px-2'}>
                        {renderStatusUI(txnStage, txnStatus, FTStatuses)}
                    </div>
                    {(txnStage === 'NSE_SUBMISSION' && txnStatus === 'FAILED') && <div className='px-2'>
                        <DLText id={'nse-error-modal'}
                                text={'see more'}
                                isClickable={true}
                                onClick={()=>{
                                    handleOpenNSEErrorsModal()
                                }}
                                marginBottom={'none'}
                        />
                    </div>}
                </div>
            </div>
        )
    };

    const renderChequePaymentDetails = () => {
        return (
            <div>
                <div className={`h-100 bg-white p-3 ${isMobileView ? 'black-border-bottom black-border-top' : 'rounded-top'}`}>
                    <div className={`d-flex justify-content-between`}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                        <DLButton
                            id={'edit-transaction'}
                            label={'Edit'}
                            buttonSize={"sm"}
                            fontSize={"sm"}
                            styleType={"outlined"}
                            hidden={txnStage !== "DRAFT"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => {
                                handleOpenEditPaymentModal()
                            }}/>
                    </div>
                </div>
                <div className="h-100 bg-white p-3 pt-0 rounded-bottom">

                    <div>
                        {renderInfoNote()}
                    </div>
                    <div className={'pe-3 mt-3 rounded-bottom'}>
                        <div className={`${!isMobileView ? 'row mb-3' : 'mb-3'}`}>
                            <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={getPaymentMode(paymentDetails?.paymentMechanism?.code)}/>
                            </div>
                            <div className={`${!isMobileView ? 'col-9' : ''}`}>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                        </div>
                        <div className={`${!isMobileView ? 'row' : ''}`}>
                            <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                                <DLText id={'cheque-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Cheque Number'}/>
                                <DLText id={'cheque-number-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.chequeNo}/>
                            </div>
                            <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                                <DLText id={'micr-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'MICR Number'}/>
                                <DLText id={'micr-number-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.micrNo}/>
                            </div>
                            <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                                <DLText id={'date'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Date (date on cheque)'}/>
                                <DLText id={'date-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={isEmpty(paymentDetails?.chequePayment?.chequeDate) ? "" : moment(paymentDetails?.chequePayment?.chequeDate).format("DD-MM-YYYY")}/>
                            </div>
                            <div className={`${!isMobileView ? 'col-3' : 'pb-3  '}`}>
                                <DLText id={'cheque-deposite-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Cheque Deposite Mode'}/>
                                <DLText id={'cheque-deposite-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={getDepositMode(paymentDetails?.chequePayment?.chequeDepositMode)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderPaymentDetails = () => {
        if (paymentDetails?.paymentMechanism?.code === "Q") {
            return renderChequePaymentDetails()
        }
        if (isMobileView) {
            return (
                <div className={"h-100 bg-white border-rounded"}>
                    <div className={'d-flex justify-content-between align-items-center black-border-bottom p-3 pb-3 black-border-top'}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'lg'}
                            fontWeight={'bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                        <DLButton
                            id={'edit-transaction'}
                            label={'Edit'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            hidden={txnStage !== "DRAFT"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => {
                                handleOpenEditPaymentModal()
                            }}/>
                    </div>
                    <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3 p-3'}
                         style={{maxHeight: 'calc(100dvh - 480px)'}}>
                        <div className='row mb-3'>
                            <div className={"mb-3"}>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                            <div className={isMobileView ? "" : "col-3"}>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={getPaymentMode(paymentDetails?.paymentMechanism?.code)}/>
                            </div>
                        </div>
                        {paymentDetails?.paymentMechanism?.description !== "Bank Mandate" && <div className='row'>
                            <div>
                                <DLText id={'RTGS/IFSC-code'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'RTGS/IFSC Code'}/>
                                <DLText id={'RTGS/IFSC-code-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.bankAccount?.IFSCCode}/>
                            </div>
                        </div>}
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        hidden={txnStage !== "DRAFT"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenEditPaymentModal()
                        }}/>
                </div>
                <div>
                    {renderInfoNote()}
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={getPaymentMode(paymentDetails?.paymentMechanism?.code)}/>
                        </div>
                        <div className='col-9'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    {paymentDetails?.paymentMechanism?.description !== "Bank Mandate" && <div className='row'>
                        <div className='col-3'>
                            <DLText id={'RTGS/IFSC-code'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'RTGS/IFSC Code'}/>
                            <DLText id={'RTGS/IFSC-code-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.bankAccount?.IFSCCode}/>
                        </div>
                    </div>}
                </div>
            </div>
        )
    };

    const renderBankNameAndAccountNumberUi = (bankAccountDetails) => {
        if (isEmpty(bankAccountDetails?.bankName) && isEmpty(bankAccountDetails?.accountNumber)) {
            return "-"
        }
        return (
            <div>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={getBankName(bankAccountDetails?.bankName)}/>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={" - " + bankAccountDetails?.accountNumber}/>
            </div>
        )
    }

    const renderInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded p-3 h-100">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <DLText
                                id={'investment-amount-title'}
                                type={'normal'}
                                text={'Investment Amount: ' + handleAmount(txnData?.investmentAmount)}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                fontColor={'black'}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-center'>
                            <DLText id={''}
                                    text={'No schemes have been added for the funds'}
                                    fontWeight={'normal'}
                                    fontSize={"sm"}
                            />
                            <div className='d-flex'>
                                <DLButton id={'add-button'}
                                          onClick={() => handleAddOrEditTransactionDetails("ADD")}
                                          hidden={txnStage !== "DRAFT"}
                                          label={'Add Scheme'}/>
                                <DLButton id={'select-button'}
                                          onClick={() => handleAddOrEditTransactionDetails("EDIT")}
                                          hidden={txnStage !== "DRAFT"}
                                          label={'Select Portfolio'}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'investment-amount-title'}
                        type={'normal'}
                        text={'Investment Amount: ' + handleAmount(txnData?.investmentAmount)}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT"}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100 c">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row px-0 m-0"}>
                <div className='data-card-label col-5'>
                    <DLText id={'schemes'}
                            text={"Schemes"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3'>
                    <DLText id={'folio-no'}
                            text={"Folio No"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'allocation'}
                                text={"Allocation"}
                                marginBottom={"none"}
                                fontWeight={"semi-bold"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-1 text-end'>
                    <DLText id={'amount'}
                            text={"Amount"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-5'/>
                <div className='data-card-label col-3'/>
                <div className='data-card-label col-3'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'total-percentage'}
                                text={isEmpty(txnData?.totalAllocationPercentage) ? "-" : getRoundedvalue(txnData?.totalAllocationPercentage) + "%"}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-1 text-end'>
                    <DLText id={'total-allocation'}
                            text={isEmpty(txnData?.totalAllocationAmount) ? "-" : handleAmount(txnData?.totalAllocationAmount)}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-5'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontWeight={"semi-bold"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-3'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-3'>
                                    <div className={'text-end h-100'} style={{maxWidth: "150px"}}>
                                        <div className='d-flex align-items-center justify-content-end h-100'>
                                            <DLText id={''}
                                                    text={item?.allocation + "%"}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className='data-card-label col-1'>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={handleAmount(item?.amount)}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderDraftButtons = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'move-to-draft-Btn'}
                    history={props.history}
                    label={'Move To Draft'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenMoveToDraftModal()
                    }}
                />
            </div>
        )
    };

    const renderResendButton = () => {
        const {handleOpenResendModal} = props;

        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'resend-enail-Btn'}
                    history={props.history}
                    label={'Resend Email To Client'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenResendModal()
                    }}
                />
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div
                className={isMobileView ? "sticky-bottom-buttons gap-0 bg-white mt-1" : "sticky-bottom-buttons gap-0 "}>
                {!isEmpty(txnData?.totalAllocationAmount) && !isMobileView
                    &&
                    <div
                        className={isMobileView ? 'd-flex justify-content-end align-items-center gap-1  p-2' : 'd-flex justify-content-end align-items-center gap-1  me-2'}>
                        <DLText id={''}
                                text={'Current payment amount'}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                fontColor={"grayDark"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={'Rs.' + handleAmount(txnData?.totalAllocationAmount)}
                                fontSize={"lg"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                    </div>}
                <DLButton
                    id={'discardBtn'}
                    history={history}
                    label={'Discard'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    type={'danger'}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenDiscardTransactionModal()
                    }}/>
                <DLButton
                    id={'send-to-management-Btn'}
                    history={props.history}
                    label={'Send to Management'}
                    isDisabled={isEmpty(txnData?.totalAllocationAmount) || isEmpty(paymentId)}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenSendToManagementModal()
                    }}
                />
            </div>
        )
    };

    const renderMobileData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"p-3 m-0"}
                                 key={index}>
                                <div className='data-card-label pb-2'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontColor={'black'}
                                            fontWeight={"semi-bold"}
                                            fontSize={"sm"}
                                    />
                                </div>
                                <div className='row'>
                                    <div className='data-card-label col-6'>
                                        <div className='d-flex align-items-center h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'Folio No'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                        marginBottom={"none"}
                                                />
                                                <DLText id={''}
                                                        text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-3'>
                                        <div className='d-flex align-items-center justify-content-start h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'Amount'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        marginBottom={"none"}
                                                        isInline={false}
                                                />
                                                <DLText id={''}
                                                        text={handleAmount(item?.amount)}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-3'>
                                        <div className={'text-start'} style={{maxWidth: "150px"}}>
                                            <div className='d-flex align-items-center justify-content-start h-100'>
                                                <div>
                                                    <DLText id={''}
                                                            text={'Allocation'}
                                                            fontColor={'grayDark'}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                            marginBottom={"none"}
                                                            isInline={false}
                                                    />
                                                    <DLText id={''}
                                                            text={item?.allocation + "%"}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileTotalCount = () => {
        return (
            <div className={"py-2 black-border-bottom row px-0 m-0 bg-white black-border-top"}>
                <div className='data-card-label col-6'/>
                <div className='data-card-label col-3 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                text={isEmpty(txnData?.totalAllocationAmount) ? "-" : handleAmount(txnData?.totalAllocationAmount)}
                        />
                    </div>
                </div>
                <div className='data-card-label col-3 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'} style={{maxWidth: "150px"}}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                text={isEmpty(txnData?.totalAllocationPercentage) ? "-" : txnData?.totalAllocationPercentage + "%"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100">
                    <div className='p-3 border-top border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <DLText
                                id={'investment-amount-title'}
                                type={'normal'}
                                text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                fontColor={'black'}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='text-center my-5'>
                            <DLText id={''}
                                    text={'No schemes have been added for the funds'}
                                    fontWeight={'normal'}
                                    fontSize={"sm"}
                            />
                            <DLButton id={'add--button'}
                                      onClick={() => handleAddOrEditTransactionDetails("ADD")}
                                      hidden={txnStage !== "DRAFT"}
                                      label={'Add'}/>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-light border-rounded">
                <div>
                    <div
                        className='d-flex justify-content-between bg-white align-items-center p-3 border-top border-bottom'>
                        <DLText
                            id={'investment-amount-title'}
                            type={'normal'}
                            text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                            fontSize={'md'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                        <DLButton
                            id={'edit-transaction'}
                            label={'Edit'}
                            hidden={txnStage !== "DRAFT"}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                    </div>
                </div>
                <div>
                    <div className='p-0 w-100 h-100 bg-white'>
                        <div className="h-100 c">
                            {renderMobileData()}
                            {renderMobileTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDetails = () => {
        return (
            <div className={`${isMobileView ? '' : 'h-100'} bg-light`}>
                <div className={`d-flex flex-column h-100`}>
                    <div className={`${isMobileView ? 'mb-4' : 'flex-grow-1 mb-2'}`}>
                        {isMobileView ? renderMobileInvestmentDetails() : renderInvestmentDetails()}
                    </div>
                    <div className={isMobileView ? '' : 'mb-5'}>
                        {renderPaymentDetails()}
                    </div>
                </div>
            </div>
        )
    };

    const renderError = () => {
        if (txnStatus === "REJECTED" && !isEmpty(rejectComment)) {
            return (
                <div className='mb-3 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {rejectComment}
                    </div>
                </div>
            )
        }
    };

    const renderModalError = () => {
        if (responseError) {
            return (
                <div className='mb-3 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            )
        }
    };

    const renderDesktopUI = () => {
        return (
            <div className='d-flex flex-column bg-light h-100'>
                {renderDesktopHeader()}
                <div className="page-container">
                    {renderModalError()}
                </div>
                <div className='page-container flex-grow-1 bg-light'>
                    {renderError()}
                    {renderDetails()}
                </div>
                {txnStage === "DRAFT" && renderButtons()}
                {txnStatus === "REJECTED" && renderDraftButtons()}
                {txnStatus === "IN_PROGRESS" && txnStage === "CLIENT_APPROVAL" && renderResendButton()}
            </div>
        );
    };

    const renderMobileHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-top">
                        <i className='fa-solid fa-chevron-left cursor fa-xs pt-3 mt-0'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div>
                            <div className="ps-4">
                                <div className='d-flex align-items-top fs-3' style={{lineHeight: '30px'}}>
                                    Financial Transaction: Lumpsum Purchase
                                </div>
                            </div>
                            <div className='d-flex align-items-center page-container ms-2'>
                                <DLText id={'ac-details-name'}
                                        fontWeight={'semi-bold'}
                                        text={accountType + ' -'}
                                        marginBottom={"none"}
                                />
                                <div className='px-2'>
                                    {renderAccountDetails()}
                                </div>
                                <div className='d-flex align-items-center'>
                                    <DLText id={'category'}
                                            text={'(' + category + ')'}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='pe-2 ps-4 pt-2 d-flex align-items-center'>
                                    {renderStatusUI(txnStage, txnStatus, FTStatuses)}
                                </div>
                                {(txnStage === 'NSE_SUBMISSION' && txnStatus === 'FAILED') && <div className='page-container ms-2'>
                                    <DLText id={'nse-error-modal'}
                                            text={'see more'}
                                            isClickable={true}
                                            onClick={()=>{
                                                handleOpenNSEErrorsModal()
                                            }}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/*<div className='d-flex justify-content-end align-items-top'>*/}
                    {/*    {renderMenuOptions()}*/}
                    {/*</div>*/}

                </div>

            </div>
        )
    };

    const renderAmount = () => {
        return (
            <div className={'pb-4 mb-4'}>
                {!isEmpty(txnData?.totalAllocationAmount) &&
                    <div className='d-flex align-items-center gap-1 bg-white p-2 mb-1 black-border-bottom black-border-top'>
                        <DLText id={''}
                                text={'Payment Amount: '}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                fontColor={"grayDark"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={handleAmount(txnData?.totalAllocationAmount)}
                                fontSize={"lg"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                    </div>
                }
            </div>
        )
    }

    const renderMobileUI = () => {
        return (
            <div className='d-flex flex-column h-100'>
                {renderMobileHeader()}
                <div className="page-container">
                    {renderModalError()}
                </div>
                <div className='flex-grow-1'>
                    {renderError()}
                    {renderDetails()}
                </div>
                <div>
                    {txnStage === "DRAFT" && renderAmount()}
                    <div>
                        {txnStage === "DRAFT" && renderButtons()}
                        {txnStatus === "REJECTED" && renderDraftButtons()}
                    </div>
                </div>
            </div>
        );
    };

    const renderInfoNote = () => {
        return (
            <div id='financial-categorization-note'>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-panel ms-0' style={{backgroundColor: appTheme.appColor.info}}>
                        <DLText id={'info'}
                                text={
                                    <>
                                        <DLText id={'info-icon'}
                                                text={<i className="fa-solid fa-circle-info pe-1"
                                                         style={{color: appTheme.appColor.primary}}/>}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                        <DLText id={'info-description'}
                                                text={'Note that all fields in the payment details section are required.'}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                    </>
                                }
                                marginBottom={"none"}
                                fontSize={'xs'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    return (
        <div className='h-100'>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
            {renderDiscardTransactionModal()}
            {renderSendToManagementTransactionModal()}
            {renderEditPaymentModal()}
            {renderMoveToDraftModal()}
            {renderResendModal()}
            {renderCopyTransactionModal()}
            {renderNSEErrors()}
        </div>
    );
};

export default FTDetailsForCRMView;