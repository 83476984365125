import { configureStore } from '@reduxjs/toolkit'
import appReducer from "../src/AppComponents/AppReducer";
import userReducer from "./user/userReducer";
import leadsReducer from "./leads/leadsReducer";
import onboardingReducer from "./onboarding/listing/onboardingReducer"
import onboardingFlowReducer from "./onboarding/onboardingFlowReducer"
import clientsReducer from "./clients/components/clientsListing/clientListingReducer"
import NFTReducer from "./transactions/NFT/NFTReducer"
import FTReducer from "./transactions/FT/FTReducer"
import portfolioReducer from "./portfolio/portfolioReducer"
import clientRequestsReducer from "./clientRequest/ClientRequestsReducer";
import FTReportReducer from "./transactions/FT/components/FTReportList/FTReportReducer";


const store = configureStore({
  reducer: {
    appState: appReducer,
    userState: userReducer,
    leadState: leadsReducer,
    clientRequests: clientRequestsReducer,
    onboardingState : onboardingReducer,
    onboardingFlowState : onboardingFlowReducer,
    clientsState : clientsReducer,
    NFTState : NFTReducer,
    FTState : FTReducer,
    portfolioState : portfolioReducer,
    FTReportState: FTReportReducer
  }
});
export default store;
