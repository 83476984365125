import React from 'react';

import DLModal from "../../../../../core/components/DLModal/DLModal";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLModalTitle from "../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import DLModalActions from "../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../../../core/components/DLText/DLText";
import DLRadioGroup from "../../../../../core/components/DLRadioGroup/DLRadioGroup";
import {numberWithCommas} from "../../../../../utils/helper";
import DLDatePicker from "../../../../../core/components/DLDatePicker/DLDatePicker";
import DLCheckbox from "../../../../../core/components/DLCheckbox/DLCheckbox";
import DLDropDown from "../../../../../core/components/DLDropdown/DLDropDown";
import moment from "moment";

const AddNewTransactionView = (props) => {

    const {
        history,
        isAddNewTransactionDialogOpen,
        handleCloseAddNewTransaction,
        isShowLoader,
        modalErrorMessage,
        handleNext,
        handleChange,
        transactionOptions,
        txnOption,
        errors,
        isTxnOptionSelected,
        amount,
        handleInitiate,
        frequencyData,
        bankDetails,
        selectedFrequency,
        selectedBankAccountId,
        startDate,
        endDate,
        isMaxPeriodSelected,
        setMaxPeriod
    } = props;

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className=' align-items-center d-flex message-block'>
                    <div className="error-container pb-3">
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {modalErrorMessage}
                        </div>
                    </div>
                </div>
            )
        }
    };

    const renderTransactionOptions = () => {
        return (
            <div className='mb-1 pb-1 align-items-center d-flex message-block'>
                <div>
                    <DLText id={''}
                            text={"Select Transaction Type"}
                            fontColor={errors.txnOptionError !== '' ? 'danger' : 'grayDark'}
                            fontSize={"xs"}
                            marginBottom={"md"}
                    />
                    <div>
                        <DLRadioGroup
                            id={'transactionOptions-radioBtn'}
                            options={transactionOptions}
                            label={''}
                            value={txnOption}
                            type={'block'}
                            labelFontSize={'sm'}
                            labelFontColor={'black'}
                            onChangeValue={(value) => {
                                handleChange("txnOption", value)
                            }}
                            error={errors.txnOptionError !== ''}
                            helperMessage={errors.txnOptionError}
                            helperMessageColor={"danger"}
                            disableHelperMessageHeight={true}
                            disableDescriptionMessageHeight={true}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderSIPTransaction = () => {
        return (
            <div className={'text-start'} style={{width: '100%'}}>
                <DLInputTextField
                    id={'sip-amount'}
                    label={'SIP Amount'}
                    labelFontSize={'sm'}
                    labelFontColor={'grayDark'}
                    value={numberWithCommas(amount)}
                    marginBottom={"none"}
                    inputType={"number"}
                    isRequired={true}
                    disableHelperMessageHeight={true}
                    error={errors.amountError !== ""}
                    helperMessage={errors.amountError}
                    hideCharLength={true}
                    isClearable={false}
                    maxCharLength={15}
                    onChangeValue={(value) => {
                        handleChange("amount", value)
                    }}
                />
                <div className='sip-transaction-date-piker d-flex flex-row align-items-start gap-3 position-relative pt-4 w-100'>
                    <div className={'w-50'}>
                        <DLDatePicker id={'start-date'}
                                      label={'Start Date'}
                                      labelFontSize={'sm'}
                                      labelFontColor={'grayDark'}
                                      value={startDate}
                                      minimumDate={moment()}
                                      isClearable={true}
                                      isRequired={true}
                                      onChangeValue={(value) => {
                                          handleChange('startDate', value);
                                      }}
                                      disableHelperMessageHeight={true}
                                      error={errors.startDateError !== ''}
                                      helperMessage={errors.startDateError}
                        />
                    </div>
                    <div className={'w-50'}>
                        <DLDatePicker id={'end-date'}
                                      label={'End Date'}
                                      labelFontSize={'sm'}
                                      labelFontColor={'grayDark'}
                                      value={endDate}
                                      minimumDate={moment().add(12, 'months').format('YYYY-MM-DD')}
                                      maximumDate={moment().add(30, 'years').format('YYYY-MM-DD')}
                                      isClearable={true}
                                      isRequired={true}
                                      onChangeValue={(value) => {
                                          handleChange('endDate', value);
                                      }}
                                      disableHelperMessageHeight={true}
                                      error={errors.endDateError !== ''}
                                      helperMessage={errors.endDateError}
                                      isDisabled={isMaxPeriodSelected}
                        />
                    </div>
                </div>
                <div className={'d-flex align-items-start pt-2 gap-2'}>
                    <DLCheckbox id={'maximum-period'}
                                isChecked={isMaxPeriodSelected}
                                label={'Maximum Period'}
                                labelPlacement={'end'}
                                fontSize={'sm'}
                                labelColor={'black'}
                                onChangeValue={() => {
                                    setMaxPeriod()
                                }}

                    />
                </div>
                <div className={'border-bottom'}>
                    <DLDropDown id={'frequency-dropdown'}
                                label={'Frequency'}
                                value={selectedFrequency}
                                labelPlacement={'top'}
                                labelFontSize={'sm'}
                                minWidth={'100%'}
                                options={getFormatedFrequencyOptions(frequencyData)}
                                onSelect={(e) => {
                                    handleChange('selectedFrequency', e)
                                }}
                                disableHelperMessageHeight={true}
                                error={errors.selectedFrequencyError !== ''}
                                helperMessage={errors.selectedFrequencyError}
                                labelFontColor={errors.selectedFrequencyError ? ('danger') : 'grayDark'}
                    />
                </div>
                <div className={'pt-3'}>
                    <DLText id={'debit-mandate'}
                            text={'Debit Mandate'}
                            fontColor={'black'}
                            fontSize={'md'}
                            iconPosition={'start'}
                            marginBottom={'none'}
                            uiType={'text'}

                    />
                </div>
                <div className={'pt-3'}>
                    <DLDropDown id={'account-details-dropdown'}
                                label={'Bank Name & Account Number'}
                                value={selectedBankAccountId}
                                labelPlacement={'top'}
                                labelFontSize={'sm'}
                                minWidth={'100%'}
                                options={getFormatedAccountOptions(bankDetails)}
                                onSelect={(e) => {
                                    handleChange('selectedBankAccountId', e)
                                }}
                                disableHelperMessageHeight={true}
                                error={errors.selectedBankAccountIdError !== ''}
                                helperMessage={errors.selectedBankAccountIdError}
                                labelFontColor={errors.selectedBankAccountIdError ? ('danger') : 'grayDark'}
                    />

                </div>

            </div>
        )
    }

    const renderAmountUi = () => {
        const {txnOption} = props;
        if (txnOption === 'SIP') {
            return (
                renderSIPTransaction()
            )
        }
        if (txnOption === 'LUMPSUM') {
            return (
                <div className='py-2 text-start'>
                    <DLInputTextField
                        id={'lumpsum-amount'}
                        label={'Investment Amount'}
                        value={numberWithCommas(amount)}
                        marginBottom={"none"}
                        inputType={"number"}
                        isRequired={true}
                        disableHelperMessageHeight={true}
                        error={errors.amountError !== ""}
                        helperMessage={errors.amountError}
                        hideCharLength={true}
                        isClearable={false}
                        maxCharLength={15}
                        onChangeValue={(value) => {
                            handleChange("amount", value)
                        }}
                    />
                </div>

            )
        }
    }
    const getFormatedAccountOptions = (bankDetails) => {
        return bankDetails.map(item => ({
            value: item.id,
            label: item.bankName + '-' + item.accountNumber
        }));
    }

    const getFormatedFrequencyOptions = (frequencyData) => {
        return frequencyData?.map(item => ({
            value: item.code,
            label: item.description,
        }));
    }

    const renderUi = () => {
        if (isTxnOptionSelected) {
            return (
                <div className={'px-1'}>
                    {renderAmountUi()}
                </div>
            )
        }
        return (
            <div>
                {renderTransactionOptions()}
            </div>
        )
    };

    const renderButtonsUi = () => {
        if (isTxnOptionSelected) {
            return (
                <div>
                    <DLButton
                        id={'new-transaction-cancelBtn'}
                        history={history}
                        label={'Cancel'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleCloseAddNewTransaction()}/>
                    <DLButton
                        id={'new-transaction-initiateBtn'}
                        history={props.history}
                        label={'Initiate'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        sideMargin={"sm"}
                        onClick={() => handleInitiate()}
                    />
                </div>
            )
        }
        return (
            <div>
                <DLButton
                    id={'new-transaction-cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseAddNewTransaction()}/>
                <DLButton
                    id={'new-transaction-assignBtn'}
                    history={props.history}
                    label={'Next'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleNext()}
                />
            </div>
        )
    };

    const renderModal = () => {
        const {isXSView} = props;
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    isFullScreen={isXSView}
                    onClose={() => handleCloseAddNewTransaction()}
                    open={isAddNewTransactionDialogOpen}
                    id={'new-transaction-modal'}>
                    <DLModalTitle
                        id={'new-transaction-title'}
                        onClose={() => handleCloseAddNewTransaction()}
                        title={'New Transaction'}/>
                    <DLModalContainer
                        id="new-transaction-container"
                        padding="none">
                        <div className="sip-popup px-3 py-3" >
                            {renderModalError()}
                            <div>
                                {renderUi()}
                            </div>
                        </div>
                    </DLModalContainer>

                    <DLModalActions id={'new-transaction-action'}>
                        <div className={'pt-1'}>
                            {renderButtonsUi()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const overlayRemove = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'new-transaction'}
                            fontSize={'xl'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'New Transaction'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseAddNewTransaction()}/>
                    </div>
                </div>
                <div>
                    <div className={'px-3 py-3'}
                         style={{
                             maxHeight: 'calc(100dvh-190px)',
                             overflow: 'auto',
                         }}>
                        {renderModalError()}
                        <div>
                            {renderUi()}
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        {renderButtonsUi()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader}/>
        )
    };

    return (
        <div>
            {renderLoader()}
            {renderModal()}
        </div>
    );
};

export default AddNewTransactionView;