import React from "react";

import {convertUTCToLocalTime, numberWithCommas} from "../../../../../utils/helper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import FTReportStatusModal from "../components/FTReportStatusModal/FTReportStatusModal";

import {
    headingFTReportData,
    headingFTDataMobile,
    FTReportCellConfig,
    FTReportCellConfigMobile
} from "../../../FTModal";

import DLMenuOptions from "../../../../../core/components/DLMenuOptions/DLMenuOptions";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import DLText from "../../../../../core/components/DLText/DLText";
import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../../../assets/appTheme";

const FTReportListForCRMView = (props) => {
    const {
        isMobileView,
        isMDView,
        isXSView,
        onChangeFilter,
        isShowLoader,
        isShowLoaderMaster,
        isStatusModalOpen,
        handleStatusModalClose,
        handleStatusModalOpen,
        FTReportListDetails,
        getFTReportDetails,
        FTReportFilter
    } = props;

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    }

    const validate = (value) => {
        return !isEmpty(value) ? ' - ' + value : '';
    }

    const getFormattedTableData = (data) => {
        let tableData = [];
        if (isEmpty(data)) {
            return tableData;
        }

        data.map((txn) => {
            let transactions = [];
            let transactionDetails = {};

            if (!isXSView) {
                transactions.push(getAccountUI(txn));

                transactions.push(isEmpty(txn?.trxnType) ? '-' : txn?.trxnType)

                transactions.push(isEmpty(txn?.folioNo) ? '-' : txn?.folioNo);

                transactions.push(isEmpty(txn?.schemeName) ? '-' : txn?.schemeName);

                transactions.push(isEmpty(txn?.entryDate) ? '-' : convertUTCToLocalTime(txn?.entryDate, 'DD-MM-YYYY'));

                transactions.push(isEmpty(txn?.amount) ? '-' : txn?.amount);

                transactions.push({
                    isCustomUI: true,
                    customUI: renderActionButton(txn?.id)
                })
            } else {
                transactions.push({
                    isCustomUI: true,
                    customUI: renderAccountMobileUI(txn),
                });

                transactions.push({
                    isCustomUI: true,
                    customUI: renderAmountTxnMobileUI(txn),
                })
            }

            transactionDetails.id = txn?.id;
            transactionDetails.data = transactions;
            tableData.push(transactionDetails);
        })

        return tableData;
    }

    const renderHeader = () => {
        return (
            <div className={(!isXSView ? 'bg-light' : '') +
                ' d-flex justify-content-between pb-1 pt-3'}>
                <div className='page-header pt-0 pb-3'>
                    Financial Transaction Report
                </div>
            </div>
        )
    };

    const renderFilterMobile = () => {

        return (
            <div className='d-flex align-items-start align-content-start justify-content-between gap-3 page-container'>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField
                        id={'report-list-search-filter'}
                        marginBottom={"none"}
                        rows={1}
                        styleType={"search"}
                        onChangeValue={(e) => {
                            onChangeFilter('keyword', e)
                        }}
                        value={FTReportFilter.FTReportKeyword}
                        placeholder={'Search by Name'}
                        isSearchable={true}
                        inputHeight={'sm'}
                        inputType={"text"}
                        disableHelperMessageHeight={true}
                    />
                </div>
            </div>

        )
    };

    const renderFilter = () => {

        return (
            <div className='search-input me-3 mb-3' style={{flex: 1.5}}>
                <DLInputTextField
                    id={'report-list-search-filter'}
                    label={'Search'}
                    labelFontSize={'sm'}
                    labelFontColor={'grayDark'}
                    marginBottom={"none"}
                    rows={1}
                    styleType={"search"}
                    onChangeValue={(e) => {
                        onChangeFilter('keyword', e)
                    }}
                    value={FTReportFilter.FTReportKeyword}
                    placeholder={'Search by Name'}
                    isSearchable={true}
                    size="small"
                    inputHeight={'sm'}
                    inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                    inputType={"text"}
                    disableHelperMessageHeight={true}
                />
            </div>

        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isShowLoaderMaster}/>);
    };

    const renderCount = () => {
        const {listingCount} = props;

        return (
            <div className={!isMDView && isMobileView ? ' page-container mt-4 mb-3' : 'd-flex align-items-center pb-3'} style={{height: '37px'}}>
                {!isEmpty(listingCount) && listingCount !== 0 &&
                    <div className={'d-flex align-items-center '}>
                        <DLText id={'count-text'}
                                fontSize={'sm'}
                                isInline={true}
                                fontColor={'grayDark'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + listingCount + ' records'}/>

                        <i className="fa-solid fa-rotate-right cursor mx-2" onClick={() => {
                            getFTReportDetails()
                        }}/>
                    </div>}
            </div>
        )

    };

    const renderStatusModal = () => {

        if (isStatusModalOpen) {
            return (
                <FTReportStatusModal
                    {...props}
                    handleStatusModalClose={handleStatusModalClose}
                />
            )

        }
    }

    const renderAccountHolders = (accountHolders, accountType) => {
        let name = ''
        if (isEmpty(accountHolders)) {
            return '-';
        }
        accountHolders.map((item, index) => {
            if (accountType !== 'MINOR') {
                if (index <= (accountHolders?.length - 2)) {
                    name = name + item?.fullName + ', '
                } else {
                    name = name + item?.fullName
                }
            } else {
                if (item?.holder_type === 'PRIMARY_HOLDER_MINOR') {
                    name = name + item?.fullName
                }
            }
        })

        return (
            <DLText id={'account-holder-names'}
                    text={name}
                    fontSize={"xs"}
                    fontWeight={"normal"}
                    fontColor={isXSView ? 'darkBlue' : 'black'}
            />
        );
    }

    const renderActionButton = (id) => {
        const {handleActionClicked, isActionClicked, actionBtnOptions} = props;

        return (
            <div className="d-flex justify-content-around">
                <div className="px-3" style={{fontSize: appTheme.fontSizes.md}}
                     onClick={() => handleActionClicked(!isActionClicked, id)}>
                    <DLMenuOptions
                        id={"menuOption"}
                        minWidth={'60px'}
                        onSelect={() =>
                            handleStatusModalOpen()
                        }
                        options={actionBtnOptions}
                    />
                </div>
            </div>
        )
    }

    const getAccountUI = (account) => {
        const {accountCategories, getCategory, getAccountType} = props;

        return (
            <div className='py-1'>

                {renderAccountHolders(account?.accountHolders, account?.accountType)}

                <DLText id={'account-type-details'}
                        text={getAccountType(account?.accountType) + ' ' + validate(account?.IINNumber) + ' ' + getCategory(account?.category, accountCategories)}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        fontColor={"grayDark"}
                />
            </div>
        )
    }

    const renderAccountMobileUI = (txn) => {
        const {accountCategories, getCategory, getAccountType} = props;

        return (
            <div className='py-3'>
                {renderAccountHolders(txn?.accountHolders, txn?.accountType)}
                <DLText id={'account-type-details'}
                        text={getAccountType(txn?.accountType) + ' ' + validate(txn?.IINNumber) + ' ' + getCategory(txn?.category, accountCategories)}
                        fontSize={'xxs'}
                        fontWeight={'normal'}
                        fontColor={"grayDark"}
                />
            </div>
        )
    }

    const renderAmountTxnMobileUI = (txn) => {
        return (
            <div className='d-flex py-3 align-content-start'>
                <div className='w-75'>
                    <DLText id={'amount'}
                            text={'₹ ' + handleAmount(txn?.amount)}
                            fontSize={'xxs'}
                            fontWeight={'bold'}
                    />
                    <DLText id={'transaction-type'}
                            text={txn?.trxnType}
                            fontSize={'xxs'}
                            fontWeight={'normal'}
                            fontColor={'primary'}
                    />
                </div>
                {renderActionButton(txn?.id)}
            </div>

        )
    }

    const renderLastRow = () => {
        let totalAmount = 0;

        FTReportListDetails?.fundReportItems?.map((fund) => {
            if (!isEmpty(fund?.amount)) {
                totalAmount += Number(fund?.amount)
            }
        });
        return [
            {
                isCustomUI: true,
                customUI: (
                    <div className="text-start">
                        <DLText
                            id={'total-amount'}
                            text={'Amount: ' + numberWithCommas(totalAmount)}
                            fontWeight={"semi-bold"}
                        />
                    </div>
                ),
            },
            "",
            "",
            "",
            "",
            "",
            "",
        ];
    };

    const renderTableUI = () => {
        const {
            isShowFilterMobile, handleChangePage, handleChangeRowsPerPage,
            getPaginationNextButtonStatus, skipCount, listingCount, FTReportFilter
        } = props;

        let tableData = getFormattedTableData(FTReportListDetails?.fundReportItems || []);

        if (isEmpty(tableData)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{minHeight: 'calc(100dvh - 277px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100 position-relative">
                <DLTable
                    id={'ft-report-list-for-crm'}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    isRowClickable={false}
                    headerData={!isXSView ? headingFTReportData : headingFTDataMobile}
                    tableData={tableData}
                    cellConfig={!isXSView ? FTReportCellConfig : FTReportCellConfigMobile}
                    isShowPagination={isMDView || !isMobileView}
                    isShowTotalCount={isMDView || !isMobileView}
                    totalCountRow={renderLastRow()}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: FTReportFilter.FTReportRowsPerPage,
                            currentPage: FTReportFilter.FTReportPage,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0
                        }
                    }
                />
            </div>
        )
    };

    const renderFTTable = () => {
        return (
            <div className='w-100'>
                {renderCount()}
                {renderTableUI()}
            </div>
        )
    };

    const renderUi = () => {
        const {UIState} = props;

        if (UIState === '') {
            return
        }
        return (
            <div className={'h-100 d-flex justify-content-center' + (!isMDView && isMobileView ? '' : ' bg-light ')}
                 style={{minHeight: !isMDView && isMobileView ? 'calc(100dvh - 58px)' : 'calc(100dvh - 52px)'}}>
                <div className={'w-100'}>
                    {renderHeader()}
                    {(!isMDView && isMobileView) ?
                        renderFilterMobile() :
                        <div className={'page-container bg-light'}>
                            {renderFilter()}
                        </div>
                    }
                    <div className={!isMDView && isMobileView ? '' : ' bg-light page-container'}>
                        {renderFTTable()}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderUi()}
            {renderLoader()}
            {renderStatusModal()}
        </div>
    );
}

export default FTReportListForCRMView
