import React from 'react'

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {convertUTCToLocalTime, numberWithCommas} from "../../../../../utils/helper";

import {
    FTReportCellConfigForManagement,
    FTReportCellConfigMobile,
    headingFTDataMobile,
    headingFTReportDataForManagement,

} from "../../../FTModal";

import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLMenuOptions from "../../../../../core/components/DLMenuOptions/DLMenuOptions";
import DLText from "../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import emptyFilter from "../../../../../assets/img/appIcons/emptyFilter.png";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import DLDropDown from "../../../../../core/components/DLDropdown/DLDropDown";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";

import FTReportStatusModal from "../components/FTReportStatusModal/FTReportStatusModal";
import filterDot from "../../../../../assets/img/appIcons/filterDot.png";
import emptyFilterFilled from "../../../../../assets/img/appIcons/emptyFilterFilled.png";
import appTheme from "../../../../../assets/appTheme";

const FTReportListForManagementView = (props) => {
    const {
        isMobileView,
        isXSView,
        isMDView,
        onChangeFilter,
        isShowLoader,
        isShowLoaderMaster,
        isStatusModalOpen,
        handleStatusModalClose,
        handleStatusModalOpen,
        FTReportListDetails,
        resetFTReportListing,
        getAccountType,
        getCRM,
        getFTReportDetails,
    } = props;


    const handleAmount = (amount) => {
        return isEmpty(amount) ? "-" : numberWithCommas(amount);

    }

    const validate = (value) => {
        return !isEmpty(value) ? ' - ' + value : '';
    }

    const getFormattedTableData = (data) => {
        let tableData = [];
        if (isEmpty(data)) {
            return tableData;
        }

        data.map((txn) => {
            let transactions = [];
            let transactionDetails = {};

             if (!isXSView) {
                transactions.push(getAccountUI(txn));

                transactions.push(isEmpty(txn?.trxnType) ? '-' : txn?.trxnType)

                transactions.push(isEmpty(txn?.folioNo) ? '-' : txn?.folioNo);

                transactions.push(isEmpty(txn?.schemeName) ? '-' : txn?.schemeName);

                transactions.push(isEmpty(txn?.entryDate) ? '-' : convertUTCToLocalTime(txn?.entryDate, 'DD-MM-YYYY'));

                transactions.push(isEmpty(txn?.amount) ? '-' : txn?.amount);

                transactions.push(renderCRMLocation(txn));

                transactions.push({
                    isCustomUI: true,
                    customUI: renderActionButton(txn?.id)
                })
            } else {
                 transactions.push({
                     isCustomUI: true,
                     customUI: renderAccountMobileUI(txn),
                 });

                 transactions.push({
                     isCustomUI: true,
                     customUI: renderAmountTxnMobileUI(txn),
                 })
             }

            transactionDetails.id = txn?.id;
            transactionDetails.data = transactions;
            tableData.push(transactionDetails);
        })

        return tableData;
    }

    const isFiltersApplied = (filters) => {

        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (key === 'CRMId' && filters[key][0] === 'ALL') {
                        return false
                    }
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    };

    const renderHeader = () => {
        return (
            <div
                className={(!isXSView ? 'bg-light' : '') + ' d-flex justify-content-between pb-1 pt-3'}>
                <div className='page-header pt-0 pb-3'>
                    Financial Transaction Report
                </div>
            </div>
        )
    };

    const renderFilterIcon = () => {
        const {FTReportFilter, isShowFilterMobile} = props;

        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(FTReportFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    }

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter, CRMDropdownValues, FTReportFilter} = props;

        if (isEmpty(CRMDropdownValues)) {
            return
        }
        return (
            <div>
                <div className='d-flex align-items-start align-content-start justify-content-between gap-3 page-container'>
                    <div className='search-input' style={{flex: 8}}>
                        <DLInputTextField id={'report-list-search-filter'}
                                          marginBottom={"none"}
                                          rows={1}
                                          styleType={"search"}
                                          onChangeValue={(e) => {
                                              onChangeFilter('keyword', e)
                                          }}
                                          value={FTReportFilter.FTReportKeyword}
                                          placeholder={'Search by Name'}
                                          isSearchable={true}
                                          inputHeight={'sm'}
                                          inputType={"text"}
                                          disableHelperMessageHeight={true}

                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <DLButton
                            id={'filter-button'}
                            padding={'none'}
                            styleType={'outlined'}
                            type={'icon'}
                            startIcon={renderFilterIcon()}
                            onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        />

                        {isFiltersApplied(FTReportFilter) &&
                            <i className="fa-solid fa-xmark cursor ms-2 "
                                   onClick={() => resetFTReportListing()}
                                   style={{fontSize: 18}}/>
                        }
                    </div>
                </div>
                {isShowFilterMobile &&
                    <div className='bg-light mt-2'
                         style={{
                             paddingTop: 10,
                             paddingBottom: 10
                         }}>
                        <div
                            className='page-container d-flex align-items-start justify-content-between flex-wrap gap-2 gap-wrap-3'>
                            <div style={{flex: 1}}>
                                <DLDropDown
                                    id={"crm-filter"}
                                    label={'CRM'}
                                    labelFontColor={'black'}
                                    labelPlacement={'top'}
                                    value={FTReportFilter.FTReportCRMId[0]}
                                    options={CRMDropdownValues}
                                    onSelect={(e) => {
                                        onChangeFilter('CRMId', e)
                                    }}
                                    disableHelperMessageHeight={true}
                                    minWidth={'100%'}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    };

    const renderFilter = () => {
        const {CRMDropdownValues, FTReportFilter} = props;

        return (
            <div className='bg-light'>
                <div className='d-flex bg-light'>
                    <div className='search-input me-3' style={{flex: 1.5}}>
                        <DLInputTextField
                            id={'report-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={FTReportFilter.FTReportKeyword}
                            placeholder={'Search by Name'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='select-filter me-3 mb-3' style={{minWidth: "300px"}}>
                        <DLDropDown
                            id={"crm-filter"}
                            label={'CRM'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={FTReportFilter.FTReportCRMId[0]}
                            minWidth={"100%"}
                            options={CRMDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('CRMId', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return <DLLoader type={"screen"} isVisible={isShowLoader || isShowLoaderMaster}/>;
    };

    const renderCount = () => {
        const {listingCount} = props;

        return (
            <div className={!isMDView && isMobileView ? ' page-container mt-4 mb-3' : 'd-flex align-items-center pb-3'} style={{height: '37px'}}>
                {!isEmpty(listingCount) && listingCount !== 0 &&
                    <div className={'d-flex align-items-center '}>
                        <DLText id={'count-text'}
                                isInline={true}
                                fontSize={'sm'}
                                fontColor={'grayDark'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + listingCount + ' records'}/>

                        <i className="fa-solid fa-rotate-right cursor mx-2"
                           onClick={() => {
                               getFTReportDetails();
                           }}/>

                    </div>
                }
            </div>
        )
    };

    const renderStatusModal = () => {

        if (isStatusModalOpen) {
            return <FTReportStatusModal
                {...props}
                handleStatusModalClose={handleStatusModalClose}
            />
        }
    }

    const renderAccountHolders = (accountHolders, accountType) => {
        let name = '';

        if (isEmpty(accountHolders)) {
            return '-';
        }
        accountHolders.map((item, index) => {
            if (accountType !== 'MINOR') {
                if (index <= (accountHolders?.length - 2)) {
                    name = name + item?.fullName + ', '
                } else {
                    name = name + item?.fullName
                }
            } else {
                if (item?.holder_type === 'PRIMARY_HOLDER_MINOR') {
                    name = name + item?.fullName
                }
            }
        })

        return (
            <DLText id={'account-holder-names'}
                    text={name}
                    fontSize={"xs"}
                    fontWeight={"normal"}
                    fontColor={isXSView ? 'darkBlue' : 'black'}
            />
        );
    }

    const renderActionButton = (id) => {
        const {handleActionClicked, isActionClicked, actionBtnOptions} = props;

        return (
            <div className="d-flex justify-content-around">
                <div className="px-3" style={{fontSize: appTheme.fontSizes.md}}
                     onClick={() => handleActionClicked(!isActionClicked, id)}>
                    <DLMenuOptions
                        id={"menuOption"}
                        minWidth={'60px'}
                        onSelect={() =>
                            handleStatusModalOpen()
                        }
                        options={actionBtnOptions}
                    />
                </div>
            </div>
        )
    }

    const getAccountUI = (account) => {
        const {accountCategories, getCategory} = props;
        return (
            <div className='py-1'>
                {renderAccountHolders(account?.accountHolders, account?.accountType)}
                <DLText id={'account-type-details'}
                        text={getAccountType(account?.accountType) + ' ' + validate(account?.IINNumber) + ' ' + getCategory(account?.category, accountCategories)}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        fontColor={"grayDark"}
                />
            </div>
        )
    }

    const renderAccountMobileUI = (txn) => {
        const {accountCategories, getCategory} = props;

        return (
            <div className='py-3'>
                {renderAccountHolders(txn?.accountHolders, txn?.accountType)}
                <DLText id={'account-type-details'}
                        text={getAccountType(txn?.accountType) + ' ' + validate(txn?.IINNumber) + ' ' + getCategory(txn?.category, accountCategories)}
                        fontSize={'xxs'}
                        fontWeight={'normal'}
                        fontColor={"grayDark"}
                />
            </div>
        )
    }

    const renderAmountTxnMobileUI = (txn) => {
        return (
            <div className='d-flex py-3'>
                <div className='w-75'>
                    <DLText id={'amount'}
                            text={'₹ ' + handleAmount(txn?.amount)}
                            fontSize={'xxs'}
                            fontWeight={'bold'}
                    />
                    <DLText id={'transaction-type'}
                            text={txn?.trxnType}
                            fontSize={'xxs'}
                            fontWeight={'normal'}
                            fontColor={'primary'}
                    />
                </div>
                {renderActionButton(txn?.id)}
            </div>

        )
    }

    const renderCRMLocation = (txn) => {
        const {CRMDropdownValues} = props;
        return (
            <div>
                <DLText id={'crm-name'}
                        text={getCRM(txn?.assignedTo, CRMDropdownValues)}
                        fontSize={'xs'}
                />
                <DLText id={'location'}
                        text={isEmpty(txn?.location) ? '-' : txn?.location}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        fontColor={'grayDark'}
                />
            </div>
        )
    }

    const renderLastRow = () => {
        let totalAmount = 0;

        FTReportListDetails?.fundReportItems?.map((fund) => {
            if (!isEmpty(fund?.amount)) {
                totalAmount += Number(fund?.amount)
            }
        });
        return [
            {
                isCustomUI: true,
                customUI: (
                    <div className="text-start">
                        <DLText
                            id={'total-amount'}
                            fontWeight={"semi-bold"}
                            text={'Amount: ' + numberWithCommas(totalAmount)}
                        />
                    </div>
                ),
            },
            "",
            "",
            "",
            "",
            "",
            "",
            ""
        ];
    };

    const renderTableUI = () => {
        const {
            isShowFilterMobile, handleChangePage, handleChangeRowsPerPage,
            getPaginationNextButtonStatus, skipCount, listingCount, FTReportFilter
        } = props;

        let tableData = getFormattedTableData(FTReportListDetails?.fundReportItems || []);

        if (isEmpty(tableData)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{minHeight: 'calc(100dvh - 277px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={'ft-report-list-for-management'}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    isRowClickable={false}
                    headerData={!isXSView ? headingFTReportDataForManagement : headingFTDataMobile}
                    tableData={tableData}
                    cellConfig={!isXSView ? FTReportCellConfigForManagement : FTReportCellConfigMobile }
                    isShowPagination={isMDView || !isMobileView}
                    isShowTotalCount={isMDView || !isMobileView}
                    totalCountRow={renderLastRow()}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: FTReportFilter.FTReportRowsPerPage,
                            currentPage: FTReportFilter.FTReportPage,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0
                        }
                    }
                />
            </div>
        )
    };

    const renderFTTable = () => {
        return (
            <div className='w-100'>
                {renderCount()}
                {renderTableUI()}
            </div>
        )
    };

    const renderUi = () => {
        const {UIState} = props;
        if (UIState === '') {
            return
        }

        return (
            <div className={'h-100 d-flex justify-content-center' + (!isMDView && isMobileView ? '' : ' bg-light ')}
                 style={{minHeight: !isMDView && isMobileView ? 'calc(100dvh - 58px)' : 'calc(100dvh - 52px)'}}>
                <div className={'w-100'}>
                    {renderHeader()}
                    {isMDView || isMobileView ?
                        renderFilterMobile() :
                        <div className={'page-container bg-light'}>
                            {renderFilter()}
                        </div>
                    }
                    <div className={!isMDView && isMobileView ? '' : ' bg-light page-container'}>
                        {renderFTTable()}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderLoader()}
            {renderUi()}
            {renderStatusModal()}
        </div>
    );
}

export default FTReportListForManagementView;
