import React, {useEffect, useState} from 'react';

import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../utils/validations";

import {connect} from "react-redux";

import {getClientBankAccount} from "../../../../../../clients/ClientsActions";
import {
    editPaymentDetails,
    getChequeDepositModes,
    getPaymentDetails,
    getPaymentModes
} from "../../../../FTAction";

import EditPaymentDetailsView from "./EditPaymentDetailsView";

const EditPaymentDetails = (props) => {
    const {onSaveSuccess, handleCloseEditPaymentModal, txnRequestId, paymentId, getBankName} = props;
    const [editPaymentDatailsState, setEditPaymentDatailsState] = useState({
        bankDetails: [],
        bankAccounts: [],
        paymentModeDropdownValue: [],
        paymentModes: [],
        chequeDepositModes: [],
        selectedBankDetails: "",
        selectedPaymentMode: '',
        chequeNumber: '',
        MICRNumber: '',
        chequeDate: '',
        selectedChequeDepositMode: [],
        isApiInProgress: false,
        responseError: '',
        UIState : '',
        errors: {
            selectedBankDetailsError: '',
            selectedPaymentModeError: '',
            selectedChequeDepositModeError: '',
            chequeNumberError: '',
            MICRNumberError: '',
            chequeDateError: ''
        }
    });

    useEffect(() => {
        getTransactionOptions()
    }, [])

    useEffect(() => {
        if (!isEmpty(paymentId)) {
            getExistingPaymentDetails()
        }
    }, [paymentId])

    const getTransactionOptions = () => {
        const {accountId} = props;

        getClientBankAccount(accountId).then(res => {
            if (res?.success) {
                getPaymentModes().then(paymentModeRes => {
                    if (paymentModeRes?.success) {

                        getChequeDepositModes().then(chequeDepositModeRes => {
                            if (chequeDepositModeRes?.success) {
                                setEditPaymentDatailsState(prevState => ({
                                    ...prevState,
                                    bankDetails: res.data?.banks,
                                    bankAccounts: setValuesForBanksDropdown(res.data?.banks),
                                    paymentModeDropdownValue: setValuesForDropdown(paymentModeRes.data?.paymentMechanisms, 'description', 'id'),
                                    paymentModes: paymentModeRes.data?.paymentMechanisms,
                                    chequeDepositModes: setValuesForDropdown(chequeDepositModeRes.data?.chequeDepositModes, 'name', 'id'),
                                    UIState: APP_CONST.CONTENT_AVAILABLE,
                                    responseError: ''
                                }));
                            } else {
                                setEditPaymentDatailsState(prevState => ({
                                    ...prevState,
                                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                    responseError: res.__error
                                }));
                            }
                        })

                    }
                })
            }
        })
    };

    const getExistingPaymentDetails = () => {
        setEditPaymentDatailsState(prevState => ({
            ...prevState,
            isApiInProgress: true
        }));

        getPaymentDetails(paymentId).then(paymentDetailsRes => {
            if (paymentDetailsRes?.success) {
                setEditPaymentDatailsState(prevState => ({
                    ...prevState,
                    selectedBankDetails: paymentDetailsRes?.data?.bankAccount?.id,
                    selectedPaymentMode: paymentDetailsRes?.data?.paymentMechanismId,
                    chequeNumber: paymentDetailsRes?.data?.chequePayment?.chequeNo,
                    MICRNumber: paymentDetailsRes?.data?.chequePayment?.micrNo,
                    chequeDate: paymentDetailsRes?.data?.chequePayment?.chequeDate,
                    selectedChequeDepositMode: paymentDetailsRes?.data?.chequePayment?.chequeDepositMode,
                    isApiInProgress: false,
                    responseError: ''
                }));
            } else {
                setEditPaymentDatailsState(prevState => ({
                    ...prevState,
                    isApiInProgress: false,
                    responseError: res.__error
                }));
            }
        })
    }

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return [];
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const setValuesForBanksDropdown = (data) => {
        if (isEmpty(data)) {
            return [];
        }
        return data.map((item) => {
            return {label: getBankName(item?.bankName) + " " + item?.accountNumber, value: item?.id}
        });
    };

    const resetState = () =>{
        let resetState = {
            chequeNumber: '',
            MICRNumber: '',
            chequeDate: '',
            selectedChequeDepositMode: []
        }
        return resetState;
    }

    const handleChange = (name, value) => {
        let updatedState = {...editPaymentDatailsState};
        if(name === "selectedPaymentMode"){
            updatedState[name] = value;
            updatedState = {...updatedState,...resetState()}

        }else{
            updatedState[name] = value;
        }

        const validateObject = validateChanges(name, updatedState);

        setEditPaymentDatailsState(prevStep => ({
            ...prevStep,
            ...updatedState,
            responseError: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const validateChanges = (name, compState) => {
        const {selectedBankDetails, selectedPaymentMode, chequeNumber, MICRNumber, chequeDate, selectedChequeDepositMode} = compState;
        let errorObj = {...editPaymentDatailsState.errors};

        if (name === "selectedBankDetails" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(selectedBankDetails)) {
                errorObj.selectedBankDetailsError = "Please select bank details";
            } else {
                errorObj.selectedBankDetailsError = "";
            }

            if (name === 'selectedBankDetails') {
                return errorObj;
            }
        }
        if (name === "selectedPaymentMode" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(selectedPaymentMode)) {
                errorObj.selectedPaymentModeError = "Please select payment mode";
            } else {
                errorObj.selectedPaymentModeError = "";
            }

            if (name === 'selectedPaymentMode') {
                return errorObj;
            }
        }
        if (name === "chequeNumber" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(chequeNumber)) {
                errorObj.chequeNumberError = "Please enter cheque number";
            } else {
                errorObj.chequeNumberError = "";
            }

            if (name === 'chequeNumber') {
                return errorObj;
            }
        }
        if (name === "MICRNumber" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(MICRNumber)) {
                errorObj.MICRNumberError = "Please enter MICR number";
            } else {
                errorObj.MICRNumberError = "";
            }

            if (name === 'MICRNumber') {
                return errorObj;
            }
        }
        if (name === "chequeDate" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(chequeDate)) {
                errorObj.chequeDateError = "Please enter cheque date";
            } else {
                errorObj.chequeDateError = "";
            }

            if (name === 'chequeDate') {
                return errorObj;
            }
        }
        if (name === "selectedChequeDepositMode" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(selectedChequeDepositMode)) {
                errorObj.selectedChequeDepositModeError = "Please select cheque deposit mode";
            } else {
                errorObj.selectedChequeDepositModeError = "";
            }

            if (name === 'selectedChequeDepositMode') {
                return errorObj;
            }
        }
        return errorObj;
    };

    const checkIfValidateObj = (validateObj) => {
        const {selectedPaymentMode} = editPaymentDatailsState;
        const {selectedBankDetailsError, selectedPaymentModeError, chequeNumberError, MICRNumberError, chequeDateError,
            selectedChequeDepositModeError} = validateObj;
        let paymentType = getPaymentMechanism(selectedPaymentMode);
        if(paymentType === "Q"){
            if (selectedBankDetailsError || selectedPaymentModeError || chequeNumberError || MICRNumberError || chequeDateError || selectedChequeDepositModeError) {
                // If any mandatory error is present (non-empty), return false
                return false;
            }
        }
        if (selectedBankDetailsError || selectedPaymentModeError) {
            // If any mandatory error is present (non-empty), return false
            return false;
        }
        // If no mandatory errors are present, return true
        return true;
    };

    const handleEditBankAccountDetails = () => {

        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, editPaymentDatailsState);
        if (!checkIfValidateObj(validateObj)) {
            setEditPaymentDatailsState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }

        setEditPaymentDatailsState((preState) => ({
                ...preState,
                isApiInProgress: true,
            }
        ));

        let payload = {
            paymentId: paymentId,
            txnRequestId: txnRequestId,
            paymentMechanismId: editPaymentDatailsState?.selectedPaymentMode,
            bankAccountId: editPaymentDatailsState?.selectedBankDetails,
            chequeNo: editPaymentDatailsState?.chequeNumber,
            micrNo: editPaymentDatailsState?.MICRNumber,
            chequeDate: editPaymentDatailsState?.chequeDate,
            chequeDepositeMode: editPaymentDatailsState?.selectedChequeDepositMode
        };
        let type = "ADD";
        if (!isEmpty(paymentId)) {
            type = "EDIT"
        }

        editPaymentDetails(payload, true, type).then(res => {
            if (res?.success) {
                setEditPaymentDatailsState((prevStep) => ({
                    ...prevStep,
                    isApiInProgress: false,
                }));
                onSaveSuccess();
                handleCloseEditPaymentModal();
            } else {
                setEditPaymentDatailsState((prevStep) => ({
                    ...prevStep,
                    isApiInProgress: false,
                    responseError: res.__error
                }));
            }
        })
    };

    const getIFSCCode = (selectedId) => {
        let bank = ''
        const {bankDetails} = editPaymentDatailsState;
        bank = bankDetails?.find((item) => item?.id === selectedId);
        return bank;
    }

    const getPaymentMechanism = (selectedId) => {
        let paymentMode = ''
        const {paymentModes} = editPaymentDatailsState;
        paymentMode = paymentModes?.find((item) => item?.id === selectedId);
        return paymentMode?.code;
    }

    return (
        <EditPaymentDetailsView
            {...props}
            {...editPaymentDatailsState}
            handleChange={handleChange}
            handleEditBankAccountDetails={handleEditBankAccountDetails}
            getIFSCCode={getIFSCCode}
            getPaymentMechanism={getPaymentMechanism}
        />
    );
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(EditPaymentDetails);
