import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";

import {
    getTxnReportDetails,
} from "../../../FTAction";
import {getCategoryOptions} from "../../../../NFT/NFTAction";
import {getClientAccountTypes} from "../../../../../clients/ClientsActions";
import {
    resetFTReportFilters,
    setFTReportKeywordValue,
    setFTReportLimitCount,
    setFTReportPageCount,
    setFTReportRowsPerPage,
    setFTReportSkipCount
} from "../FTReportAction";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import FTReportListForCRMView from "./FTReportListForCRMView";

const FTReportListForCRM = (props) => {
    const {FTReportFilter} = props;

    useEffect(() => {
        getFTReportDetails()

    }, [FTReportFilter]);

    useEffect(() => {
        getCategoryDetails();
    }, [])


    const [FTReportListState, setFTReportListState] = useState({
        FTReportListDetails: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 50,
        pageCount: 0,
        UIState: '',
        isActionClicked: false,
        isStatusModalOpen: false,
        isShowLoader: false,
        isShowLoaderMaster: false,
        selectedId: '',
        filters: {
            keyword: '',
            status: []
        },
        categoryOptions: [],
        accountTypeOptions: [],
        accountCategories: [],
        actionBtnOptions: [{
            label: "Status",
            value: "STATUS"
        }],
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const getCategoryDetails = () => {

        setFTReportListState((prevState) => ({
            ...prevState,
            isShowLoaderMaster: true
        }))

        getCategoryOptions().then(CategoryOptionsRes => {
            if (CategoryOptionsRes?.success) {

                getClientAccountTypes().then((accountTypeRes) => {
                    if (accountTypeRes?.success) {


                        setFTReportListState((prevState) => ({
                            ...prevState,
                            categoryOptions: setValuesForDropdown(CategoryOptionsRes?.data?.categoryType, "name", "id"),
                            accountTypeOptions: setValuesForDropdown(accountTypeRes?.data?.accountType, "name", "id"),
                            accountCategories: CategoryOptionsRes?.data?.categoryType,
                            isShowLoaderMaster: false,
                        }))

                        setPage(isEmpty(FTReportFilter.FTReportPage) ? 0 : FTReportFilter.FTReportPage);
                        setRowsPerPage(isEmpty(FTReportFilter.FTReportRowsPerPage) ? 50 : FTReportFilter.FTReportRowsPerPage);
                    } else {
                        setFTReportListState((prevState) => ({
                            ...prevState,
                            isShowLoaderMaster: false,
                        }))
                    }
                })
            }
        })

    }

    const getFTReportDetails = () => {
        const {skipCount, limitCount} = props;

        let payload = {
            "skipCount": skipCount,
            "limitCount": limitCount,
            "filter": generateFilter(FTReportFilter),
        }

        setFTReportListState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }))

        getTxnReportDetails(payload, true).then((res) => {
            if (res?.success) {

                setFTReportListState((prevState) => ({
                    ...prevState,
                    listingCount: res?.data?.count,
                    FTReportListDetails: res?.data,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    isShowLoader: false,
                }))

                setPage(isEmpty(FTReportFilter.FTReportPage) ? 0 : FTReportFilter.FTReportPage);
                setRowsPerPage(isEmpty(FTReportFilter.FTReportRowsPerPage) ? 50 : FTReportFilter.FTReportRowsPerPage);
            } else {
                setFTReportListState((prevState) => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }))
            }
        })
    }

    const generateFilter = (filter) => {

        let filterObject = {};

        if (!isEmpty(filter.FTReportKeyword)) {
            filterObject.keyword = filter.FTReportKeyword;
        }

        return filterObject;
    };

    const handleActionClicked = (isActionClicked, id) => {
        setFTReportListState((prevState) => ({
            ...prevState,
            isActionClicked: isActionClicked,
            selectedId: id
        }))
    }

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = FTReportListState;

        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setFTReportListState((prevState) => ({
            ...prevState,
            skipCount: skipCount,
            limitCount: limitCount,
            pageCount: newPage,
        }))

        setFTReportSkipCount(skipCount);
        setFTReportLimitCount(limitCount);
        setFTReportPageCount(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setFTReportListState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setRowsPerPage(parseInt(event.target.value, 10));

        setFTReportListState((prevState) => ({
            ...prevState,
            skipCount: 0,
            limitCount: parseInt(event.target.value, 10),
            pageCount: 0,
        }))

        setFTReportRowsPerPage(parseInt(event.target.value, 10));
        setFTReportLimitCount(parseInt(event.target.value, 10));
        setFTReportPageCount(0);
        setFTReportSkipCount(0);
    };

    const handleStatusModalOpen = () => {
        setFTReportListState((prevState) => ({
            ...prevState,
            isStatusModalOpen: true
        }))
    }

    const handleStatusModalClose = () => {
        setFTReportListState((prevState) => ({
            ...prevState,
            isStatusModalOpen: false,
            selectedId: '',
        }))
    }

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setFTReportListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setFTReportKeywordValue(value);
        }
    };

    const getCategory = (selectedStatus, accountCategories) => {

        if (selectedStatus === 'CORPORATE') {
            return 'Null'
        }
        if (isEmpty(selectedStatus) || isEmpty(accountCategories)) {
            return ''
        }
        if (selectedStatus === 'UNASSIGNED') {
            return 'Unassigned';
        }
        const status = accountCategories.find(status => status.id === selectedStatus);
        return status ? ' - ' + status.name : '';
    };

    const getAccountType = (value) => {
        const {accountTypeOptions} = FTReportListState;
        const accountType = accountTypeOptions.find(item => item?.value === value);
        return accountType?.label || '';
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const resetPagination = () => {

        setFTReportRowsPerPage(10);
        setFTReportLimitCount(10);
        setFTReportPageCount(0);
        setFTReportSkipCount(0);

    };

    const resetFTReportListing = () => {
        resetFTReportFilters();
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = FTReportListState;

        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    return (
        <FTReportListForCRMView
            {...props}
            {...FTReportListState}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onChangeFilter={onChangeFilter}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleActionClicked={handleActionClicked}
            handleStatusModalOpen={handleStatusModalOpen}
            handleStatusModalClose={handleStatusModalClose}
            resetFTReportListing={resetFTReportListing}
            getCategory={getCategory}
            getAccountType={getAccountType}
            getFTReportDetails={getFTReportDetails}
        />
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    profile: state.userState.profile,
    FTReportFilter: state.FTReportState.FTReportFilter,
});

export default connect(mapStateToProps, {})(FTReportListForCRM);