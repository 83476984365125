import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isEmpty} from "../../../utils/validations";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import {getAccountDetails, getClientAccountTypes, getAllTaxStatus} from "../../ClientsActions";
import ClientsDetailsView from "./clientsDetailsView";

import {
    getCategoryOptions,
    getCRMs,
    getIndustryTypeList,
    getOccupationList,
    getPhoneEmailBelongsToList,
    getPoliticallyExposedOptions, getStateList
} from "../../../onboarding/OnboardingActions";
import {isCorporateUser} from "../../../utils/helper";
import {getCountryData} from "../../../leads/LeadsActions";

const ClientsDetails = (props) => {

    const [clientsDetailsState, setClientsDetailsState] = useState({
        accountType: '',
        responseErrors: '',
        allTabs: [
            {label: 'Profile', value: 'profile'},
            // {label: 'Investments', value: 'investments'},
            {label: 'Financial Profile', value: 'FINANCIAL_PROFILE'},
            {label: 'Bank Account', value: 'BANK_ACCOUNT'},
            {label: 'Nominee', value: 'NOMINEE'}
        ],
        selectedTab: {label: 'Profile', value: 'profile'},
        accountDetails: {},
        applicantDetails: [],
        UIState: '',
        accountId: '',
        CRMs: [],
        categoryData: [],
        occupationData: [],
        industryData: [],
        belongsToData: [],
        states: [],
        countries: [],
        politicallyExposedData: [],
        accountHoldersPAN: [],
        allAccountTypes: [],
        taxStatuses: [],
        assignedCRM: '',
        isClientNomineeOpted: '',
        isCorporateClient: false,
        isReassignCRMModalOpen: false,
        isManagementUser : false
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const [CRMAnchorEl, setCRMAnchorEl] = useState(null);

    useEffect(() => {
        getDetails()
    }, []);

    const getAllPANNumbers = (allData, isCorporate) => {
        if (isCorporate) {
            return [
                {
                    PAN_Number: allData?.corporate?.PANNumber,
                    personId: '',
                    accountHolderId: ''
                }
            ]
        } else {
            let holders = allData?.accountHolders;
            let allPANNumbers = [];
            if (isEmpty(holders)) {
                return allPANNumbers
            }
            holders.map((personObj) => {
                allPANNumbers.push(
                    {
                        PAN_Number: personObj?.person?.PANNumber,
                        personId: personObj?.personId,
                        accountHolderId: personObj?.id
                    }
                )
            });
            return allPANNumbers
        }

    };

    const getAccountId = () => {
        return props.match?.params?.accountId
    };

    const getCRMNameById = (CRMDropdownValues) => {

        let allCRMById = [];
        if (isEmpty(CRMDropdownValues)) {
            return "";
        }
        CRMDropdownValues.forEach((item) => {
            let firstName = isEmpty(item?.firstName) ? '' : item?.firstName + ' ';
            let lastName = isEmpty(item?.lastName) ? '' : item?.lastName + ' ';
            let CRMName = firstName + lastName;
            allCRMById.push({
                id: item?.id,
                CRMName: CRMName,
                email: item?.email,
                phoneNumber: item?.phoneNumber
            })
        });
        return allCRMById;
    };

    const getAccountType = (accountType, allAccountTypes) => {
        if (isEmpty(accountType) || isEmpty(allAccountTypes)) {
            return '-'
        }
        let tempAccountType = '';

        allAccountTypes.map((item) => {
            if (item?.id === accountType) {
                tempAccountType = item?.name
            }
        });
        return (isEmpty(tempAccountType)) ? '-' : tempAccountType + ' Account'
    };

    const getDetails = () => {
        const{profile} = props;
        let accountId = getAccountId();
        let CRMs;
        let categoryData = [];
        let taxStatuses = [];
        let states = [];

        setClientsDetailsState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        getCRMs().then((CRMRes) => {
            if (CRMRes?.success) {
                CRMs = getCRMNameById(CRMRes.data?.advisoryUsers);
            } else {
                CRMs = [];
            }

            getCategoryOptions()
                .then(categoryRes => {
                    if (categoryRes.success) {
                        categoryData = categoryRes?.data?.categoryType;
                    }
                    getOccupationList()
                        .then(occupationRes => {
                            const occupationData = occupationRes?.data?.occupation;

                            getIndustryTypeList()
                                .then(industryRes => {
                                    const industryData = industryRes?.data?.industryType;

                                    getPhoneEmailBelongsToList()
                                        .then(belongsToRes => {
                                            const belongsToData = belongsToRes?.data?.relationship;

                                            getPoliticallyExposedOptions()
                                                .then(pepRes => {
                                                    const politicallyExposedData = pepRes?.data?.pep;

                                                    getClientAccountTypes()
                                                        .then(accountTypeRes => {
                                                            const accountTypes = accountTypeRes?.data?.accountType;

                                                            getAllTaxStatus()
                                                                .then(taxStatusRes => {
                                                                    taxStatuses = taxStatusRes?.data?.taxStatus;

                                                                    getStateList()
                                                                        .then(statesRes => {
                                                                            states = statesRes?.data?.state;

                                                                            getCountryData()
                                                                                .then(res => {
                                                                                    let country = res.data.country;

                                                                                    getAccountDetails(accountId)
                                                                                        .then((res) => {
                                                                                            let updatedState = {
                                                                                                isShowLoader: false,
                                                                                                CRMs: CRMs,
                                                                                                accountId: accountId,
                                                                                                categoryData: categoryData,
                                                                                                occupationData: occupationData,
                                                                                                industryData: industryData,
                                                                                                belongsToData: belongsToData,
                                                                                                politicallyExposedData: politicallyExposedData,
                                                                                                allAccountTypes: accountTypes,
                                                                                                taxStatuses: taxStatuses,
                                                                                                states: states,
                                                                                                countries: country
                                                                                            };

                                                                                            if (res?.success) {
                                                                                                let isCorporateClient = isCorporateUser(res?.data?.accountType);
                                                                                                setClientsDetailsState((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    ...updatedState,
                                                                                                    assignedCRM: res?.data?.advisoryUserId,
                                                                                                    accountDetails: res?.data,
                                                                                                    accountHoldersPAN: getAllPANNumbers(res?.data, isCorporateClient),
                                                                                                    IIN: res?.data?.accountIINDetails?.IIN,
                                                                                                    accountType: getAccountType(res?.data?.accountType, accountTypes),
                                                                                                    applicantDetails: getApplicantDetails(res?.data, isCorporateClient),
                                                                                                    UIState: APP_CONST.CONTENT_AVAILABLE,
                                                                                                    isClientNomineeOpted: res?.data?.isNomineeOpted ? 'YES' : 'NO',
                                                                                                    isCorporateClient: isCorporateClient,
                                                                                                    isManagementUser : profile.isManagement
                                                                                                }));

                                                                                            } else {
                                                                                                setClientsDetailsState((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    ...updatedState,
                                                                                                    responseErrors: res?.__error,
                                                                                                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                                                                                    isClientNomineeOpted: '',
                                                                                                }))
                                                                                            }
                                                                                        })

                                                                                })
                                                                        })
                                                                });
                                                        })


                                                })
                                        })
                                })

                        })

                });

        });

    };

    const getPersonaFullName = (firstName, lastName) => {
        let fullName = '';

        if (isEmpty(firstName) && isEmpty(lastName)) {
            return ''
        }
        if (!isEmpty(firstName)) {
            fullName = firstName + ' '
        }
        if (!isEmpty(lastName)) {
            fullName += lastName
        }
        return fullName
    };

    const getApplicantDetails = (accountDetails, isCorporateClient) => {
        let accountHolders = accountDetails?.accountHolders;
        let updatedApplicantDetails = [];
        if (isCorporateClient) {
            return [
                {
                    personId: accountDetails?.corporate?.id,
                    name: accountDetails?.corporate?.name,
                    email: accountDetails?.corporate?.primaryEmail,
                    phone: accountDetails?.corporate?.primaryPhoneNumber,
                    holderType : ''
                }
            ]
        } else {
            if (isEmpty(accountHolders)) {
                return
            }
            accountHolders.map(applicant => {
                let personDetails = applicant?.person;
                if (applicant?.holderType === "PRIMARY_HOLDER") {
                    updatedApplicantDetails.unshift(
                        {
                            personId: applicant?.id,
                            name: getPersonaFullName(personDetails?.firstName, personDetails?.lastName),
                            email: (isEmpty(personDetails?.primaryEmail)) ? '' : personDetails?.primaryEmail,
                            phone: (isEmpty(personDetails?.primaryPhoneNumber)) ? '' : personDetails?.primaryPhoneNumber,
                            holderType : applicant?.holderType
                        }
                    )
                } else {
                    updatedApplicantDetails.push(
                        {
                            personId: applicant?.id,
                            name: getPersonaFullName(personDetails?.firstName, personDetails?.lastName),
                            email: (isEmpty(personDetails?.primaryEmail)) ? '' : personDetails?.primaryEmail,
                            phone: (isEmpty(personDetails?.primaryPhoneNumber)) ? '' : personDetails?.primaryPhoneNumber,
                            holderType : applicant?.holderType
                        }
                    )
                }
            });
        }

        return updatedApplicantDetails
    };

    const getFilledApplicantsDetails = (applicantDetails) => {
        let filledApplicantsDetails = [];
        applicantDetails.map((applicant) => {
            if (!isEmpty(applicant?.name)) {
                filledApplicantsDetails.push(applicant)
            }
        });
        return filledApplicantsDetails
    };

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const handleCRMItemOpen = event => {
        setCRMAnchorEl(event.currentTarget);
    };

    const handleCRMItemClose = () => {
        setCRMAnchorEl(null);
    };


    const handleTabChange = (item) => {
        setClientsDetailsState((prevState) => ({
            ...prevState,
            selectedTab: item
        }));
    };

    const removeGuardian = (applicantDetails) =>{
        if(isEmpty(applicantDetails)){
            return []
        }
        const filteredData = applicantDetails.filter(item => item.holderType !== "GUARDIAN");
        return filteredData
    };

    const getAssignedCRM = (id) => {
        const {CRMs} = clientsDetailsState;
        if (isEmpty(CRMs) || isEmpty(id)) {
            return '-'
        }
        let crmObj = CRMs.find(item => item?.id === id);
        return crmObj
    };

    const getStateName = (value) => {
        const {states} = clientsDetailsState;
        if (isEmpty(states) || isEmpty(value)) {
            return '-'
        }
        let state = states.find(item => item?.code === value);
        return state?.description
    };

    const getCountryName = (value) => {
        const {countries} = clientsDetailsState;
        if (isEmpty(countries) || isEmpty(value)) {
            return '-'
        }
        let state = countries.find(item => item?.code === value);
        return state?.description
    };

    const handleOpenReassignCRMModal = () => {
        setClientsDetailsState((prevState) => ({
            ...prevState,
            isReassignCRMModalOpen: true
        }));
    };

    const handleCloseReassignCRMModal = () => {
        setClientsDetailsState((prevState) => ({
            ...prevState,
            isReassignCRMModalOpen: false
        }));
    };

    if(!isEmpty(clientsDetailsState.responseErrors)){
        props.history.push('/access-denied');
    }

    return (
        <ClientsDetailsView
            {...props}
            {...clientsDetailsState}
            anchorEl={anchorEl}
            CRMAnchorEl={CRMAnchorEl}
            handleTabChange={handleTabChange}
            handleItemClose={handleItemClose}
            handleItemOpen={handleItemOpen}
            getFilledApplicantsDetails={getFilledApplicantsDetails}
            removeGuardian={removeGuardian}
            getAssignedCRM={getAssignedCRM}
            handleCRMItemOpen={handleCRMItemOpen}
            handleCRMItemClose={handleCRMItemClose}
            getStateName={getStateName}
            getCountryName={getCountryName}
            handleOpenReassignCRMModal={handleOpenReassignCRMModal}
            handleCloseReassignCRMModal={handleCloseReassignCRMModal}
            getAccountId={getAccountId}
        />
    );


};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    accountType: state.onboardingFlowState.accountType,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(ClientsDetails);