import store from "../../../../store";

import {
    resetFTReportAllFilters, setFTReportCRMIdValue,
    setFTReportLimitCountValue,
    setFTReportPageValue, setFTReportRowsPerPageValue,
    setFTReportSearchKeywordValue,
    setFTReportSkipCountValue
} from "./FTReportReducer";

export const setFTReportKeywordValue = (data) => {
    store.dispatch(setFTReportSearchKeywordValue(data));
}

export const setFTReportSkipCount = (data) => {
    store.dispatch(setFTReportSkipCountValue(data));
}

export const setFTReportLimitCount = (data) => {
    store.dispatch(setFTReportLimitCountValue(data));
}

export const setFTReportPageCount = (data) => {
    store.dispatch(setFTReportPageValue(data));
}

export const setFTReportRowsPerPage = (data) => {
    store.dispatch(setFTReportRowsPerPageValue(data));
}

export const resetFTReportFilters = (data) => {
    store.dispatch(resetFTReportAllFilters(data));
}

export const setFTReportCRMId= (data) => {
    store.dispatch(setFTReportCRMIdValue(data));
}