import React from 'react';

import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";
import TabContext from "@mui/lab/TabContext/TabContext";

import {isEmpty} from "../../../utils/validations";
import appTheme from "../../../assets/appTheme";

import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import Profile from "./components/profile/profile";
import Investment from "./components/investment/Investment"
import BankAccount from "./components/bankAccount/bankAccount";
import FinancialProfile from "./components/financialProfile/FinancialProfile";
import NomineeList from "./components/nominee/NomineeList";
import ReassignCRM from "../../components/clientsDetails/components/reassignCRM/ReassignCRM";

const ClientsDetailsView = (props) => {
    const {isMobileView, isShowLoader, UIState, handleItemClose, handleItemOpen, handleTabChange,
        getFilledApplicantsDetails, isClientNomineeOpted, isXSView, isManagementUser, removeGuardian} = props;

    const renderCRMInformation = () => {
        const {assignedCRM, getAssignedCRM, CRMAnchorEl, handleCRMItemOpen, handleCRMItemClose, handleOpenReassignCRMModal} = props;
        let crm = getAssignedCRM(assignedCRM)
        return (
            <div key={'person'}
                 className='d-flex align-items-center '>
                <div>
                    <div className={isXSView ? 'ps-3' : 'pe-2'} onClick={(event) => {
                        if(!isEmpty(crm)) {
                            handleCRMItemOpen(event)
                        }
                    }}>
                        <div>
                            <DLText
                                id={'client-details-assigned'}
                                type={'normal'}
                                fontSize={'xs'}
                                text={'CRM: '}
                                isInline={true}
                                fontColor={'grayDark'}
                            />
                            <DLText
                                id={'client-details-assigned'}
                                type={'normal'}
                                isClickable={!isEmpty(assignedCRM) && !isEmpty(crm)}
                                isInline={true}
                                text={crm?.CRMName}
                            />
                        </div>
                    </div>

                    <Menu id="items-menu"
                          anchorEl={CRMAnchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                          transformOrigin={{vertical: "top", horizontal: "left"}}
                          open={Boolean(CRMAnchorEl)}
                          onClose={() => handleCRMItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '10px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center'
                              },
                          }}>
                        <MenuItem key={'menuItem-'}
                                  className={'py-0 text-center'}
                                  style={{minWidth: 200, height: "max-content", cursor: "auto"}} disableRipple>
                            <div className='text-start'>
                                {renderCRMDetails(crm)}
                            </div>
                        </MenuItem>
                    </Menu>
                </div>
                {isManagementUser &&
                <div className='d-flex justify-content-center align-items-center cursor'>
                    <i className= {isXSView? "fa-sharp fa-solid fa-pencil me-1 ms-2" : "fa-sharp fa-solid fa-pencil me-1 ms-1"}
                       style={{fontSize: appTheme.fontSizes.xs, marginTop: isXSView? '' : '6px'}}
                       onClick={() => {handleOpenReassignCRMModal()}}
                    />
                </div>
                }
            </div>
        );
    };

    const renderReassignCRMModal = () => {
        const {isMobileView, handleCloseReassignCRMModal, isReassignCRMModalOpen} = props;

        if (isReassignCRMModalOpen) {
            if (isMobileView) {
                return (<div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseReassignCRMModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <ReassignCRM {...props} isOverlay={true}/>
                    </div>
                </div>)
            } else {
                return (
                    <ReassignCRM {...props}/>
                )
            }
        }

    };

    const renderCRMDetails = (crm) => {
        return(
            <div className='mb-1'>
                <DLText id={'client-name'}
                        text={crm?.CRMName}
                        marginBottom={"none"}
                        fontSize={"xs"}
                />
                <div>
                    <a href={'mailTo: ' + crm?.email}
                       style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                        {crm?.email}
                    </a>
                </div>
                <div>
                    <a href={'tel: ' + crm?.phoneNumber}
                       style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                        {crm?.phoneNumber}
                    </a>
                </div>
            </div>
        )

    };

    const renderHeader = () => {
        const {accountType, isXSView, IIN, applicantDetails} = props;

        return (
            <div className='w-100'>
                <div className='page-header'>
                    <div className={isXSView ? '' : 'd-flex justify-content-between'}>
                        <div className={"d-flex align-items-end"}>
                            <div className='d-flex align-items-center'>
                                <i className={'fa-solid fa-chevron-left cursor fa-2xs me-2 ' + (isMobileView ? ' pt-1 ' : '')}
                                   style={{width: 10,
                                       paddingTop:isMobileView ? '0px' : "3px"}}
                                   onClick={() => {
                                       props.history.goBack()
                                   }}
                                />
                                <div className='d-flex'>
                                    <div>
                                        Client
                                    </div>
                                    <div className='ps-1'>
                                        {(!isEmpty(accountType)) && <DLText id={''}
                                                                            text={accountType}
                                                                            fontWeight={"semi-bold"}
                                                                            fontSize={"md"}
                                                                            isInline={true}
                                                                            fontColor={"grayDark"}
                                                                            marginBottom={"none"}
                                        />}
                                    </div>
                                    <div className='ps-1'>
                                        {(!isEmpty(IIN)) && <>
                                            <DLText id={''}
                                                    text={' - '}
                                                    fontSize={"md"}
                                                    isInline={true}
                                                    marginBottom={"none"}
                                            />
                                            <DLText id={''}
                                                    text={IIN}
                                                    fontWeight={"semi-bold"}
                                                    fontSize={"md"}
                                                    isInline={true}
                                                    fontColor={"grayDark"}
                                                    marginBottom={"none"}
                                            />
                                        </>}
                                    </div>
                                </div>
                            </div>
                            {(!isEmpty(applicantDetails)) && (!isEmpty(accountType)) &&
                            <div className='d-flex align-items-center'>
                                {!isMobileView ? <div>
                                        <div className='px-1'>
                                            <DLText id={''}
                                                    text={'-'}
                                                    marginBottom={"none"}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div onClick={(event) => {
                                        handleItemOpen(event)
                                    }}>
                                        <div className='px-1'>
                                            <DLText id={''}
                                                    isClickable={(!isEmpty(getFilledApplicantsDetails(applicantDetails)))}
                                                    text={'(' + removeGuardian(applicantDetails).length + ')'}
                                                    marginBottom={"none"}
                                            />
                                        </div>
                                    </div>
                                }
                                {renderApplicantsDetails()}
                            </div>}
                        </div>
                        {renderCRMInformation()}
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileHeader = () => {
        const {accountType, IIN, applicantDetails} = props;
        const formattedAccountType = accountType
            ? accountType.replace('Account', 'A/c')
            : '';
        return (
            <div className='w-100'>
                <div className={'page-header d-flex align-items-center pb-0'}>
                    <i className={'fa-solid fa-chevron-left cursor fa-2xs me-2'}
                       style={{width: 10,}}
                       onClick={() => {
                           props.history.goBack()
                       }}
                    />
                    <div style={{lineHeight: '26px'}}>
                        Client
                    </div>
                </div>
                <div className={'d-flex flex-wrap pt-2'} style={{marginLeft: '30px'}}>
                    <div className='ps-1'>
                        {(!isEmpty(accountType)) &&
                            <DLText id={''}
                                    text={formattedAccountType}
                                    fontWeight={"semi-bold"}
                                    fontSize={"md"}
                                    isInline={true}
                                    fontColor={"grayDark"}
                                    marginBottom={"none"}
                            />}
                    </div>
                    <div className='ps-1'>
                        {(!isEmpty(IIN)) && <>
                            <DLText id={''}
                                    text={' - '}
                                    fontSize={"md"}
                                    isInline={true}
                                    marginBottom={"none"}
                            />
                            <DLText id={''}
                                    text={IIN}
                                    fontWeight={"semi-bold"}
                                    fontSize={"md"}
                                    isInline={true}
                                    fontColor={"grayDark"}
                                    marginBottom={"none"}
                            />
                        </>}
                    </div>
                    {(!isEmpty(applicantDetails)) && (!isEmpty(accountType)) &&
                        <div className={'d-flex align-items-center flex-wrap'}>
                            {
                                <div onClick={(event) => {
                                    handleItemOpen(event)
                                }}>
                                    <div className='px-1'>
                                        <DLText id={''}
                                                isClickable={(!isEmpty(getFilledApplicantsDetails(applicantDetails)))}
                                                text={'(' + removeGuardian(applicantDetails).length + ')'}
                                                marginBottom={"none"}
                                        />
                                    </div>
                                </div>
                            }

                            {renderApplicantsDetails()}
                        </div>
                    }
                </div>
                <div className={'pt-2'} style={{marginLeft: '18px'}}>
                    {renderCRMInformation()}
                </div>
            </div>
        )
    };

    const renderApplicantDetails = (applicantDetails) => {
        return applicantDetails.map((applicant, index) => {
            return (
                <div key={'applicants' + index}
                     className={(applicantDetails?.length === index + 1) ? 'mb-1' : 'border-bottom mb-1 pb-2'}>
                    <DLText id={'lead-name'}
                            text={'Applicant ' + (index + 1) + ': ' + applicant?.name}
                            marginBottom={"none"}
                            fontSize={"xs"}
                    />
                    <div>
                        {(isEmpty(applicant?.email)) ? '-' : <a href={'mailTo: ' + applicant?.email}
                           style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {applicant?.email}
                        </a>}
                    </div>
                    <div>
                        {(isEmpty(applicant?.phone)) ? '-' : <a href={'tel: ' + applicant?.phone}
                           style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {applicant?.phone}
                        </a>}
                    </div>
                </div>
            );
        });

    };

    const renderApplicantsUI = (applicantDetails) => {
        const {anchorEl} = props;

        let allApplicantsNames = "";
        applicantDetails.map((person) => {
            if (isEmpty(allApplicantsNames)) {
                allApplicantsNames = person?.name
            } else {
                allApplicantsNames = allApplicantsNames + ', ' + person?.name;
            }
        });

        return (
            <div key={'person'}
                 className='d-flex align-items-center '>
                <div>
                    {(!isMobileView) &&
                    <div className='pe-3' onClick={(event) => {
                        handleItemOpen(event)
                    }}>
                        <DLText id={'lead-name'}
                                text={allApplicantsNames}
                                marginBottom={"none"}
                                isClickable={true}
                                fontColor={"darkBlue"}
                        />
                    </div>
                    }
                    <Menu id="items-menu"
                          anchorEl={anchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                          transformOrigin={{vertical: "top", horizontal: "right"}}
                          open={Boolean(anchorEl)}
                          onClose={() => handleItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '10px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center'
                              },
                          }}>
                        <MenuItem key={'menuItem-'}
                                  className={'py-0 text-center'}
                                  style={{minWidth: 200, height: "max-content", cursor: "auto"}} disableRipple>
                            <div className='text-start'>
                                {renderApplicantDetails(applicantDetails)}
                            </div>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        );
    };

    const renderApplicantsDetails = () => {
        const {getFilledApplicantsDetails, applicantDetails, removeGuardian} = props;

        let updatedApplicantDetails = removeGuardian(applicantDetails);

        if (isEmpty(updatedApplicantDetails)) {
            return ''
        }
        let applicantsWithDetailsUploaded = getFilledApplicantsDetails(updatedApplicantDetails);
        if (applicantsWithDetailsUploaded?.length === updatedApplicantDetails?.length) {
            return renderApplicantsUI(updatedApplicantDetails)

        } else if (!isEmpty(applicantsWithDetailsUploaded)) {
            let filledApplicantDetails = applicantsWithDetailsUploaded[0];

            if (!isEmpty(filledApplicantDetails)) {
                return (
                    <div>
                        <DLText id={'lead-name'}
                                text={filledApplicantDetails?.name + ' + ' + (updatedApplicantDetails?.length - 1)}
                                marginBottom={"none"}
                                isClickable={false}
                        />
                    </div>
                )
            }
        } else {
            let totalApplicants = (updatedApplicantDetails?.length === 1) ? '1 Applicant' : updatedApplicantDetails?.length + ' Applicants';
            if (isMobileView) {
                return;
            }
            return (
                <DLText id={''}
                        text={totalApplicants}
                        marginBottom={"none"}
                        fontSize={"md"}
                        fontWeight={"semi-bold"}
                        fontColor={"grayDark"}
                />
            )
        }
    };

    const renderTabs = () => {
        const {allTabs, selectedTab} = props;

        return allTabs?.map((item, index) => {
            return (
                <Tab key={'tab_' + index}
                     label={item.label}
                     onClick={() => {
                         handleTabChange(item)
                     }}
                     style={{
                         textTransform: 'none',
                         color: appTheme.appColor.black,
                         fontFamily: appTheme.default.fontFamily,
                         fontWeight: (item?.value === selectedTab?.value) ? 500 : 400
                     }}
                     value={item.value}/>

            );
        })
    };

    const renderTabContentUI = () => {

        const {
            selectedTab, applicantDetails, accountId, accountType, accountDetails, CRMs, categoryData,
            occupationData, industryData, belongsToData, politicallyExposedData, accountHoldersPAN, taxStatuses,
            isCorporateClient, states, getStateName, getCountryName
        } = props;

        switch (selectedTab?.value) {
            case 'profile':
                return <Profile
                    history={props.history}
                    applicantDetails={applicantDetails}
                    accountId={accountId}
                    accountType={accountType}
                    accountDetails={accountDetails}
                    CRMs={CRMs}
                    states={states}
                    categoryData={categoryData}
                    occupationData={occupationData}
                    industryData={industryData}
                    belongsToData={belongsToData}
                    politicallyExposedData={politicallyExposedData}
                    accountHoldersPAN={accountHoldersPAN}
                    taxStatuses={taxStatuses}
                    isCorporateClient={isCorporateClient}
                    getStateName={getStateName}
                    getCountryName={getCountryName}
                />;
            case 'investments':
                return <Investment/>;

            case 'FINANCIAL_PROFILE':
                return <FinancialProfile
                    history={props.history}
                    accountId={accountId}
                />;
            case 'BANK_ACCOUNT':
                return <BankAccount
                    history={props.history}
                    accountId={accountId}
                />;
            case 'NOMINEE':
                return <NomineeList
                    history={props.history}
                    accountId={accountId}
                    isClientNomineeOpted={isClientNomineeOpted}
                />;
        }
    };

    const renderDesktopTabsUI = () => {

        const {selectedTab} = props;

        return (
            <div style={{flex: 0.7}}>
                <div>
                    <div className="page-container">
                        <Box sx={{width: '100%'}}>
                            <TabContext value={selectedTab?.value}>
                                <Box sx={{borderBottom: 2, borderColor: appTheme.appColor.grayLight}}>
                                    <TabList onChange={() => {
                                    }} aria-label="lab API tabs-example"
                                             variant="scrollable"
                                             scrollButtons={false}>
                                        {renderTabs()}
                                    </TabList>
                                </Box>
                            </TabContext>
                        </Box>
                    </div>
                    <div className={isMobileView ? '' : 'bg-light'}>
                        {renderTabContentUI()}
                    </div>
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? '' : 'mb-2'}>
                <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseErrors}
                    </div>
                </div>

            </div>
        );
    };

    const renderUI = () => {
        return (
            <div>
                {isXSView ? renderMobileHeader() :renderHeader()}
                <div>
                    <div className='page-container'>
                        {renderErrorUI()}
                    </div>
                    {renderDesktopTabsUI()}
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    return (
        <div>
            {renderUI()}
            {renderLoader()}
            {renderReassignCRMModal()}
        </div>
    );
};

export default ClientsDetailsView;