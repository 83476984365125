import React from 'react';
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../../../core/components/DLComponentHelper";

import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLTable from "../../../core/components/DLTable/DLTable";

import emptyLeads from '../../../assets/img/empty-leads.png';
import emptyFilter from '../../../assets/img/appIcons/emptyFilter.png';
import emptyFilterFilled from '../../../assets/img/appIcons/emptyFilterFilled.png';
import filterDot from '../../../assets/img/appIcons/filterDot.png';
import MultiselectDropdown from "../MultiselectDropdown";
import {handleKeyPress, renderStatusBlock} from "../../ClientRequestsHelper";
import {
    managementHeaderData, statusFilterOptions, cellConfig, headingMobileData
} from "../../ClientRequestsModal";
import {isCorporateUser, convertUTCToLocalTime} from "../../../utils/helper";

const ManagementListView = (props) => {
    const {
        isXSView,
        listingCount,
        rowsPerPage,
        page,
        clientRequests,
        skipCount,
        UIState,
        isMobileView,
        isShowLoader,
        isShowFilterMobile,
        clientRequestsFilter,
        CRMs,
        onChangeFilter,
        handleChangePage,
        handleChangeRowsPerPage,
        getPaginationNextButtonStatus,
        getCRMName,
        initialStatus,
        handleChange,
        selectedStatus,
        resetClientFilters,
    } = props;

    const {keyword, CRMId} = clientRequestsFilter;

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props

        if (isXSView) {
            return (<div className={'mx-3 my-2'}>
                <DLButton
                    id={'raise-a-request'}
                    styleType={'outlined'}
                    type={'icon'}
                    padding={'none'}
                    onClick={() => props.history.push("/raise-request")}
                    startIcon={<i className="fa-solid fa-plus"/>}/>
            </div>);
        }
        return (<div className={'d-flex justify-content-center my-2 me-4' + (isMobileView ? ' page-container' : '')}>
            <DLButton
                id={'raise-a-request'}
                buttonSize={'sm'}
                reduceWidth={isMobileView}
                fontSize={'sm'}
                sideMargin={'md'}
                onClick={() => props.history.push("/raise-request")}
                label={"Raise a Request"}
            />
        </div>)
    };

    const renderHeader = () => {
        return (<div className={isXSView ? 'd-flex justify-content-between align-items-center pb-1 bg-white' : 'd-flex justify-content-between pb-1 pt-3'}>
            <div className='page-header'>
                Client Requests: Financial
            </div>
            <div>
                {renderHeaderCreateButton()}
            </div>
        </div>)
    };

    const renderFilterIcon = () => {
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        return (<div className='icon-container'>
                <img src={filterIcon} alt={''} height={14} width={14}/>
                <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
            </div>
        );
    };

    const renderFilterMobile = () => {
        const {
            isShowFilterMobile, handleToggleMobileFilter,
        } = props;

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'clientRequests-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      placeholder={'Search by Name or IIN'}
                                      onKeyPress={handleKeyPress}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}
                    />
                </div>
                <div className="d-flex align-items-center" style={{marginBottom: '5px'}}>
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetClientFilters()}
                           style={{fontSize: 18}}/>
                    </div>
                </div>
            </div>
            {isShowFilterMobile && <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                <div className='page-container'>
                    <div>
                        <div>
                            <DLText
                                id={'status'}
                                text={'Status'}
                                fontColor={'grayDark'}
                            />
                            <MultiselectDropdown
                                statusFilterOptions={statusFilterOptions}
                                isXSView={isXSView}
                                onStatusChange={(selectedStatus) => onChangeFilter('status', selectedStatus)}
                                sx={{width: '100%'}}
                                handleChange={handleChange}
                                selectedStatus={selectedStatus}
                                initialStatus={initialStatus}
                            />
                        </div>
                        <div className={'pt-1'}>
                            <DLDropDown
                                id={"status-filter"}
                                label={'CRM'}
                                labelFontColor={'grayDark'}
                                labelFontSize={'sm'}
                                value={CRMId}
                                minWidth={"100%"}
                                options={CRMs}
                                onSelect={(e) => {
                                    onChangeFilter('CRMId', e);
                                }}
                                disableHelperMessageHeight={true}
                                marginBottom={"none"}
                                labelPlacement={'top'}
                            />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    };

    const renderFilter = () => {
        return (<div>
            <div className='d-flex page-container'>
                <div className='search-input' style={{flex: 1.8}}>
                    <DLInputTextField
                        id={'client-request-search-filter'}
                        label={'Search'}
                        labelFontSize={'sm'}
                        labelFontColor={'grayDark'}
                        marginBottom={"none"}
                        rows={1}
                        styleType={"search"}
                        onChangeValue={(e) => {
                            onChangeFilter('keyword', e)
                        }}
                        value={isEmpty(keyword) ? '' : keyword}
                        placeholder={'Search by Name or IIN'}
                        isSearchable={true}
                        size="small"
                        inputHeight={'sm'}
                        inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                        inputType={"text"}
                        disableHelperMessageHeight={true}
                    />
                </div>
                <div className='select-filter ms-3' style={{flex: 1}}>
                    <DLText
                        id={'status'}
                        text={'Status'}
                        fontColor={'grayDark'}
                    />
                    <MultiselectDropdown
                        statusFilterOptions={statusFilterOptions}
                        isXSView={isXSView}
                        onStatusChange={(selectedStatus) => onChangeFilter('status', selectedStatus)}
                        initialStatus={initialStatus}
                        handleChange={handleChange}
                        selectedStatus={selectedStatus}
                    />
                </div>
                <div className='select-filter ms-3' style={{flex: 1}}>
                    <DLDropDown
                        id={"status-filter"}
                        label={'CRM'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={CRMId}
                        minWidth={"100%"}
                        options={CRMs}
                        onSelect={(e) => {
                            onChangeFilter('CRMId', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
            </div>
        </div>);
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{
                        minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'
                    }}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-first-lead'}
                        text={'Please create your first request.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/raise-request")}
                    label={'Raise A Request'}/>
            </div>
        </div>
    };

    const renderMobileTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen} = props;
        let tableData = getFormattedTableData(clientRequests);

        if (isEmpty(clientRequests)) {
            return (<div className='d-flex align-items-center justify-content-center empty-records'
                         style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                No data found.
            </div>)
        }
        return (<div className="w-100 pagination-container">
            <DLTable
                id={''}
                tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 488px)' : 'calc(100dvh - 330px)'}
                tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 488px)' : 'calc(100dvh - 330px)'}
                isRowClickable={true}
                onRowClick={(rowId) => {
                    redirectToDetailsScreen(rowId)
                }}
                headerData={headingMobileData}
                cellConfig={cellConfig}
                tableData={tableData}
                pagination={{
                    totalPages: listingCount,
                    rowsPerPage: rowsPerPage,
                    currentPage: page,
                    onPageChange: handleChangePage,
                    onRowPerPageChange: handleChangeRowsPerPage,
                    enableNextButton: getPaginationNextButtonStatus(),
                    enablePrevButton: skipCount === 0
                }}

            />
        </div>)
    };

    const renderCount = () => {
        const {handleRefreshButton} = props;
        return (
            <div className={isXSView ? 'page-container mt-3 border-bottom' : isMobileView ? 'py-3' : 'pb-3'} style={{height: '37px'}}>
                {!isEmpty(listingCount) && <div className={'d-flex align-items-center m-0 p-0'}>
                    <div className={isXSView ? 'd-flex align-items-center pb-3' : 'd-flex align-items-center m-0 p-0'}>
                        <DLText id={''}
                                isInline={true}
                                fontColor={'grayDark'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + (listingCount) + ' records'}/>

                        <div className={'d-flex align-items-center m-0 p-0 mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                    </div>
                </div>}
            </div>)
    };

    const renderTextUi = (name, label, subLabel) => {

        if (name === "account") {
            return (<div className={isXSView ? "py-3" : "py-1"} style={{width: isXSView ? '' : '370px'}}>
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                marginBottom={isXSView ? "none" : "xs"}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={subLabel}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            );
        }

        if (name === "CRMAndLocation") {
            return (<div className="">
                {!isEmpty(label) && <DLText id={''}
                                            text={isEmpty(label) ? '-' : label}
                                            fontSize={"xs"}
                                            fontColor={"black"}
                />}
                {!isEmpty(subLabel) && <DLText id={''}
                                               text={subLabel}
                                               fontSize={"xs"}
                                               fontColor={"grayDark"}
                />}
            </div>);
        }

        if (name === "ticketNumber") {
            return (<div className="">
                {!isEmpty(label) && <DLText id={''}
                                            text={isEmpty(label) ? '-' : label}
                                            fontSize={"xs"}
                                            fontColor={"black"}
                />}
                {!isEmpty(subLabel) && <DLText id={''}
                                               text={subLabel}
                                               fontSize={"xs"}
                                               fontColor={"grayDark"}
                />}
            </div>);
        }

        if (name === "status") {
            return (<div className="">
                {!isEmpty(label) && <DLText id={''}
                                            text={isEmpty(label) ? '-' : label}
                                            fontSize={"xs"}
                                            fontColor={"black"}
                />}
                {!isEmpty(subLabel) && <DLText id={''}
                                               text={subLabel}
                                               fontSize={"xs"}
                                               fontColor={"grayDark"}
                />}
            </div>)
        }

        if (name === "createdDate") {
            return (<div className="">
                {!isEmpty(label) && <DLText id={''}
                                            text={convertUTCToLocalTime(label, 'DD-MM-YYYY')}
                                            fontSize={"xs"}
                                            fontColor={"black"}
                />}
            </div>);
        }

        if (name === "statusAndCreatedDate") {
            return (<div className="">
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={convertUTCToLocalTime(subLabel, 'DD-MM-YYYY')}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            )
        }

    };

    const renderCategory = (category) => {

        if (category === 'HNI') {
            return 'HNI';
        }
        if (category === 'RETAIL') {
            return 'Retail';
        }
        if (category === 'PREMIUM_RETAIL') {
            return 'Premium Retail';
        }
        if (category === 'ULTRA_HNI') {
            return 'Ultra HNI';
        }

        return (
            <div>
                <DLText id={'category'}
                        text={category}
                        fontSize={"xs"}
                        fontColor={"black"}/>
            </div>
        )

    }

    const getAccountFullName = (accountHolders, request) => {
        if (isCorporateUser(request?.accountType)) {
            return request?.corporateAccountDetails?.fullName;
        } else {
            return renderAccountHolders(accountHolders, request?.accountType)
        }
    };

    const renderAccountHolders = (accountHolders, accountType) => {
        let name = ''
        if (isEmpty(accountHolders)) {
            return '-';
        }
        accountHolders.map((item, index) => {
            if (accountType !== 'MINOR') {
                if (index <= accountHolders?.length - 2) {
                    name = name + item?.fullName + ', '
                } else {
                    name = name + item?.fullName
                }
            } else {
                if (item?.holder_type === 'PRIMARY_HOLDER_MINOR') {
                    name = name + item?.fullName
                }
            }
        })
        return isEmpty(name) ? '-' : name;
    }

    const renderAccountTypes = (accountType) => {
        if (isEmpty(accountType)) {
            return '-'
        }
        if (accountType === 'INDIVIDUAL') {
            return isXSView ? 'Individual A/c' : 'Individual Account'
        }
        if (accountType === 'CORPORATE') {
            return isXSView ? 'Corporate A/c' : 'Corporate Account'
        }
        if (accountType === 'JOINT') {
            return isXSView ? 'Joint A/c' : 'Joint Account'
        }
        if (accountType === 'PARTNERSHIP_FIRM_LLP') {
            return isXSView ? 'Partnership Firm/LLP A/c' : 'Partnership Firm/LLP Account'
        }
        if (accountType === 'TRUST') {
            return isXSView ? 'Trust A/c' : 'Trust Account'
        }
        if (accountType === 'HUF') {
            return isXSView ? 'HUF A/c' : 'HUF Account'
        }
        if (accountType === 'MINOR') {
            return isXSView ? 'Minor A/c' : 'Minor Account'
        }
        return accountType
    };

    const getAccountSubLabel = (fields) => {
        const accountType = renderAccountTypes(fields?.accountType);
        const IINNumber = fields?.IINNumber;
        const accountSubLabel = accountType + '- ' + IINNumber;
        return <div>
            <div>
                <DLText id={''}
                        isInline={true}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        fontColor={'grayDark'}
                        text={accountSubLabel}/>
            </div>
        </div>
    }

    const getFormattedTableData = (clientRequests) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(clientRequests)) {
            return tableData
        }

        clientRequests.map((selectedClientRequests) => {
            let clientRequestsRecords = [];
            let clientRequestsDetails = {};
            if (isXSView) {
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("account", getAccountFullName(selectedClientRequests?.accountHolders, selectedClientRequests), getAccountSubLabel(selectedClientRequests))
                });
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("statusAndCreatedDate", renderStatusBlock(selectedClientRequests?.status), selectedClientRequests?.openDate)
                });
            } else {
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("account", getAccountFullName(selectedClientRequests?.accountHolders, selectedClientRequests), getAccountSubLabel(selectedClientRequests))
                });
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderCategory(selectedClientRequests?.category)
                });
                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("CRMAndLocation", getCRMName(selectedClientRequests?.assignedTo), selectedClientRequests?.location)
                });

                clientRequestsRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("ticketNumber", isEmpty(selectedClientRequests?.ticketNumber) ? "" : selectedClientRequests.ticketNumber)
                });

                clientRequestsRecords.push({
                    isCustomUI: true, customUI: renderStatusBlock(selectedClientRequests?.status)
                });

                clientRequestsRecords.push({
                    isCustomUI: true, customUI: renderTextUi("createdDate", selectedClientRequests?.openDate)
                });
            }
            clientRequestsDetails.id = selectedClientRequests.clientRequestId;
            clientRequestsDetails.data = clientRequestsRecords;
            tableData.push(clientRequestsDetails)
        });

        return tableData

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen} = props;
        let tableData = getFormattedTableData(clientRequests);

        if (isEmpty(clientRequests)) {
            return (<div className='w-100 d-flex align-items-center justify-content-center panel'
                         style={{minHeight: 'calc(100dvh - 276px)'}}>
                No data found.
            </div>)
        }
        return (<div className="w-100">
            <DLTable
                id={''}
                tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 339px)'}
                tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 339px)'}
                isRowClickable={true}
                onRowClick={(rowId) => {
                    redirectToDetailsScreen(rowId)
                }}
                headerData={managementHeaderData}
                tableData={tableData}
                pagination={{
                    totalPages: listingCount,
                    rowsPerPage: rowsPerPage,
                    currentPage: page,
                    onPageChange: handleChangePage,
                    onRowPerPageChange: handleChangeRowsPerPage,
                    enableNextButton: getPaginationNextButtonStatus(),
                    enablePrevButton: skipCount === 0

                }}
            />
        </div>)
    };

    const renderClientRequestsTable = () => {
        return (<div className={'w-100' + ((isMobileView && !isXSView) ? '  page-container' : '')}
                     style={{minHeight: isMobileView ? 'auto' : 'calc(-205px + 100dvh)'}}>
            <div>
                {renderCount()}
            </div>
            {isXSView ? renderMobileTable() : <div>
                {renderDesktopTable()}
            </div>}
        </div>)
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (<div className='page-container'>
            {emptyList()}
        </div>);
    }

    if (isMobileView) {
        return (<div>
            <div className={'w-100 bg-white'}>
                {renderHeader()}
                {renderFilterMobile()}
                {renderClientRequestsTable()}
            </div>
            {renderLoader()}
        </div>);
    }

    return (<div className={'h-100 d-flex justify-content-center'}
                 style={{minHeight: 'calc(100dvh - 75px)'}}>
        <div className={isXSView ? 'w-100 bg-white' : 'w-100 bg-light'}>
            {renderHeader()}
            {renderFilter()}
            <div className={'page-container mt-3'}>
                {renderClientRequestsTable()}
            </div>
            {renderLoader()}
        </div>
    </div>);
};

export default ManagementListView;
