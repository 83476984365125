import React, {useEffect, useState} from 'react';
import {isEmpty} from "../../../utils/validations";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import {connect} from "react-redux";

import ClientRequestDetailsView from "./ClientRequestDetailsView";
import {getAdvisoryUsers} from "../../../clients/ClientsActions";
import {
    getAccountTypeOptions,
    getClientRequestById,
    getEarlierRequest,
    getTransactionRequestById
} from "../../ClientRequestsActions";
import {getFTStatuses} from "../../../transactions/FT/FTAction";
import {isCorporateUser} from "../../../utils/helper";

const ClientRequestDetails = (props) => {
    const [clientRequestDetailsState, setClientRequestDetailsState] = useState({
        companyName:'',
        name: '',
        ticketNumber: '',
        accountCategory: '',
        accountDetails: [],
        status: "",
        accountType: '',
        IINNumber: '',
        transactionsHistory: [],
        accountId: '',
        isAddNewTransactionDialogOpen: false,
        isShowLoader: false,
        category: '',
        advisoryUsers: [],
        FTStatuses: [],
        details: [],
        corporateAccountDetails: [],
        accountTypeOptions: [],
        createdBy: '',
        comments: '',
        createdAt: '',
        responseError: '',
        UIState: '',
        selectedId: '',
        isMarkAsCompletedAvailable: false,
        isMarkAsCompletedModalOpen : false,
        isDiscardTransactionModalOpen: false,
        isCopyTransactionModalOpen: false,
        selectedName:'',
        copyTxnId: ''
    });

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        if(!isEmpty(clientRequestDetailsState?.accountId)){
            let clientRequestId = getId();
            getEarlierRequestData(clientRequestDetailsState?.accountId, clientRequestId);
        }
    }, [clientRequestDetailsState?.accountId]);


    const getId = () => {
        return props.match?.params?.id
    };

    const getAdvisoryUserName = (value) => {
        const{advisoryUsers} = clientRequestDetailsState;
        if (isEmpty(value)) {
            return '-'
        }
        const user = advisoryUsers.find(item=>item.id === value)
        return user? user.firstName + " "+ user.lastName : "-";
    };

    const getDetails = () => {
        const id = getId();
        if (isEmpty(id)) {
            return
        }
        setClientRequestDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));


        getAdvisoryUsers()
            .then((advisoryRes) => {
                if (advisoryRes?.success) {

                    getFTStatuses().then(FTStatusRes => {
                        if (FTStatusRes?.success) {
                            let FTStatuses = FTStatusRes?.data;

                            getAccountTypeOptions()
                                .then(accountTypeRes => {
                                    if (accountTypeRes?.success) {

                                        getClientRequestById(id).then(res => {
                                            if (res?.success) {
                                                let requestData = res.data;

                                                getTransactionRequestById(id).then(transactionsRes => {
                                                    if (transactionsRes?.success) {
                                                        let transactionsData = transactionsRes.data;

                                                        setClientRequestDetailsState(prevState => ({
                                                            ...prevState,
                                                            id: id,
                                                            name: getFormattedData('names', res?.data?.accountDetails),
                                                            companyName: getFormattedData('companyName', res?.data?.accountDetails),
                                                            accountType: getFormattedData('accountType', requestData?.accountDetails, accountTypeRes.data?.accountType),
                                                            IINNumber: getFormattedData('IINNumber', requestData?.accountDetails),
                                                            category: getFormattedData('category', requestData?.accountDetails),
                                                            accountDetails: requestData?.accountDetails[0]?.accountHolders,
                                                            accountTypeOptions: accountTypeRes.data?.accountType,
                                                            details: requestData,
                                                            corporateAccountDetails: isEmpty(requestData?.accountDetails)? [] : requestData?.accountDetails[0]?.corporate,
                                                            ticketNumber: requestData?.ticketNumber,
                                                            createdBy: requestData?.clientRequestComment?.createdBy?.id,
                                                            comments: requestData?.clientRequestComment?.comments,
                                                            createdAt: requestData?.clientRequestComment?.createdAt,
                                                            advisoryUsers: advisoryRes?.data?.advisoryUsers,
                                                            status: requestData?.status,
                                                            accountId: requestData?.accountId,
                                                            FTStatuses: FTStatuses,
                                                            transactionsHistory: transactionsData?.txnRequests,
                                                            isMarkAsCompletedAvailable: transactionsData?.markCompleted,
                                                            isShowLoader: false,
                                                            UIState: APP_CONST.CONTENT_AVAILABLE,
                                                            responseError: ''
                                                        }));

                                                    }else {
                                                        setClientRequestDetailsState(prevState => ({
                                                            ...prevState,
                                                            isShowLoader: false,
                                                            UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                                            responseError: res?.__error
                                                        }));
                                                    }
                                                })
                                            }else {
                                                setClientRequestDetailsState(prevState => ({
                                                    ...prevState,
                                                    isShowLoader: false,
                                                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                                    responseError: res.__error
                                                }));
                                            }
                                        })

                                    }
                                })
                        }
                    })
                }
            });
    };

    const getEarlierRequestData = (id, clientRequestId) => {
        if(isEmpty(clientRequestId)){
            return;
        }
        setClientRequestDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getEarlierRequest(id, clientRequestId).then(earlierReqRes => {
            if (earlierReqRes?.success) {
                let earlierReqData = earlierReqRes.data;
                setClientRequestDetailsState(prevState => ({
                    ...prevState,
                    earlierReqHistory: earlierReqData?.clientRequests,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    responseError: ''
                }));
            }else {
                setClientRequestDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    responseError: res.__error
                }));
            }
        })
    };

    const getAccountType = (accountType, masterData) => {
        if (isEmpty(accountType) || isEmpty(masterData)) {
            return;
        }

        let label = masterData.find((item) => {
            return item?.id === accountType;
        });

        return isEmpty(label)? "" : label?.name;
    };

    function capitalizeFirstLetters(name) {
        return name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const getFormattedData = (name, accountDetails, masterData) => {
        const {isMobileView} = props
        if (name === 'names') {
            return accountDetails.flatMap(account =>
                account?.accountHolders
                    ?.filter(holder => account.accountType !== "MINOR" || holder.accountHolderType !== "GUARDIAN")
                    .map(holder => holder.fullName)
            ).join(', ');
        }
        if (name === 'companyName' && (isCorporateUser(accountDetails[0]?.accountType))) {
            return accountDetails.map(account =>
                account?.corporate?.name
            );
        }
        if (name === 'accountType') {
            const accountType = accountDetails.map(account => {
                    return account.accountType
                }
            );
            let type = getAccountType(accountType[0], masterData)
            if (isMobileView) {
                return type + ' A/c';
            } else {
                return type + ' Account';
            }
        }
        if (name === 'IINNumber') {
            return accountDetails.flatMap(account => {
                    return account.IINNumber
                }
            );
        }
        if (name === 'category') {
            return accountDetails.flatMap(account => {
                    if (account.category === 'HNI') {
                        return account.category;
                    } else if (account.category === 'UNASSIGNED') {
                        return capitalizeFirstLetters(account.category);
                    } else if (account.category === 'RETAIL') {
                        return capitalizeFirstLetters(account.category);
                    } else if (account.category === 'PREMIUM_RETAIL') {
                        return 'Premium Retail';
                    } else if (account.category === 'ULTRA_HNI') {
                        return 'Ultra HNI';
                    } else {
                        return '-';
                    }
                }
            );
        }

    };

    const handleHistoryBlockRefresh = (value) => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isHistoryBlockRefresh: value
        }))
    };

    const handleOpenAddNewTransaction = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isAddNewTransactionDialogOpen: true
        }))
    };

    const handleCloseAddNewTransaction = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isAddNewTransactionDialogOpen: false
        }))
    };

    const handleOpenMarkAsCompletedModal = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isMarkAsCompletedModalOpen : true
        }));
    };

    const handleCloseMarkAsCompletedModal = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isMarkAsCompletedModalOpen : false
        }));
    };

    const handleOpenDiscardTransactionModal = () => {
        const {companyName, name, details} = clientRequestDetailsState;
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isDiscardTransactionModalOpen: true,
            selectedName: (isCorporateUser(details?.accountDetails?.[0]?.accountType)? companyName : name)
        }));
    };

    const handleCloseDiscardTransactionModal = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isDiscardTransactionModalOpen: false,
            selectedName: ''
        }));
    };


    const handleOpenCopyTransactionModal = (txnId) => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isCopyTransactionModalOpen: true,
            copyTxnId: txnId
        }));
    };

    const handleCloseCopyTransactionModal = () => {
        setClientRequestDetailsState((prevState) => ({
            ...prevState,
            isCopyTransactionModalOpen: false,
            copyTxnId: ''
        }));
    };

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const onSaveSuccess = () => {
        getDetails();
    };

    const getAccountHolderDetails = (accountDetails, accountType) => {
        if(isEmpty(accountDetails)){
            return;
        }
        if (accountType === "MINOR") {
            return accountDetails?.filter(holder => holder?.accountHolderType !== "GUARDIAN");
        }
        return accountDetails;
    };

    const handleRedirect = (txnId) => {
        props.history.push("/client-requests/"+getId()+"/lumpsum-transaction/"+txnId)
    };

    return (
        <ClientRequestDetailsView
            {...props}
            {...clientRequestDetailsState}
            anchorEl={anchorEl}
            getId={getId}
            handleHistoryBlockRefresh={handleHistoryBlockRefresh}
            getDetails={getDetails}
            onSaveSuccess={onSaveSuccess}
            getAdvisoryUserName={getAdvisoryUserName}
            handleOpenAddNewTransaction={handleOpenAddNewTransaction}
            handleCloseAddNewTransaction={handleCloseAddNewTransaction}
            handleOpenMarkAsCompletedModal={handleOpenMarkAsCompletedModal}
            handleCloseMarkAsCompletedModal={handleCloseMarkAsCompletedModal}
            handleItemOpen={handleItemOpen}
            handleItemClose={handleItemClose}
            handleRedirect={handleRedirect}
            handleOpenCopyTransactionModal={handleOpenCopyTransactionModal}
            handleCloseCopyTransactionModal={handleCloseCopyTransactionModal}
            handleOpenDiscardTransactionModal={handleOpenDiscardTransactionModal}
            handleCloseDiscardTransactionModal={handleCloseDiscardTransactionModal}
            getAccountHolderDetails={getAccountHolderDetails}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isXLView: state.appState.deviceInfo.isXLView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ClientRequestDetails);
