export const headingFTData = ['Account', 'Ticket Number', 'Type of Request', 'Status', 'Created Date'];

export const headingFTDataForManagement = ['Account', 'Ticket Number', 'Type of Transaction', 'Status', 'Created Date', 'CRM & Location'];

export const headingFTDataMobile = ['Account', 'Amount & Transaction Type']

export const headingFTReportData = ['Account', 'Type of Transaction', 'Folio No.', 'Schemes', 'Transaction Date', 'Amount', 'Action'];

export const headingFTReportDataForManagement = ['Account', 'Type of Transaction', 'Folio No.', 'Schemes', 'Transaction Date', 'Amount', 'CRM & Location', 'Action'];

export const purchaseTransactionHeadingData = ['Schemes', 'Folio No.', 'Allocation', 'Amount', 'Action'];

export const purchaseTransactionCellConfig = [
    {
        textAlign: 'left',
        width: '35%',
        minWidth: '35%'
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'right',
        width: '100px',
        minWidth: '100px',
        paddingRight: 100
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'center',
        width: '15%',
        minWidth: '15%'
    }
];

export const FTReportCellConfig = [
    {
        minWidth: "220px",
        maxWidth: '220px',
        textAlign: "left",
    },
    {
        minWidth: "140px",
        maxWidth: '140px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "242px",
        maxWidth: '242px',
        textAlign: "left",
    },
    {
        minWidth: "120px",
        maxWidth: '120px',
        textAlign: "left",
    },
    {
        minWidth: "80px",
        maxWidth: "80px",
        textAlign: "right",
    },
    {
        minWidth: "150px",
        maxWidth: "150px",
        textAlign: "center",
    },
]

export const FTReportCellConfigForManagement = [
    {
        minWidth: "270px",
        maxWidth: '270px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "100px",
        maxWidth: '100px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "120px",
        maxWidth: "120px",
        textAlign: "right",
    },
    {
        minWidth: "150px",
        maxWidth: "150px",
        textAlign: "left",
    },
    {
        minWidth: "70px",
        maxWidth: "70px",
        textAlign: "center",
    },
]

export const FTReportCellConfigMobile = [
    {
        minWidth: "190px",
        maxWidth: "190px",
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: "150px",
        textAlign: "left",
    }
]

