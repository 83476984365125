import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";

import {getTxnReportDetails} from "../../../FTAction";
import {
    resetFTReportFilters,
    setFTReportCRMId, setFTReportKeywordValue,
    setFTReportLimitCount,
    setFTReportPageCount,
    setFTReportRowsPerPage,
    setFTReportSkipCount
} from "../FTReportAction";
import {getCategoryOptions} from "../../../../NFT/NFTAction";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {getClientAccountTypes, getCRMs} from "../../../../../clients/ClientsActions";
import FTReportListForManagementView from "./FTReportListForManagementView";

const FTReportListForManagement = (props) => {
    const {FTReportFilter} = props;

    useEffect(() => {
        getCategoryDetails();
    }, [])

    useEffect(() => {
        getFTReportDetails();
    }, [FTReportFilter]);

    const [FTReportListForManagementState, setFTReportListForManagementState] = useState({
        listingCount: 0,
        skipCount: 0,
        limitCount: 50,
        UIState: '',
        pageCount: 0,
        isActionClicked: false,
        isStatusModalOpen: false,
        isShowLoader: false,
        isShowLoaderMaster: false,
        selectedId: '',
        filters: {
            keyword: '',
            CRMId: ['ALL'],
        },
        categoryOptions: [],
        accountTypeOptions: [],
        accountCategories: [],
        CRMDropdownValues: [],
        FTReportListDetails: [],
        actionBtnOptions: [{
            label: "Status",
            value: "STATUS"
        }]
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const getCategoryDetails = () => {

        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isShowLoaderMaster: true,
        }))

        getCategoryOptions().then(res => {
            if (res?.success) {

                getClientAccountTypes().then(accountTypeRes => {
                    if (accountTypeRes?.success) {

                        getCRMs().then((CRMRes) => {
                            if (CRMRes?.success) {
                                const CRMData = CRMRes?.data?.advisoryUsers;

                                setFTReportListForManagementState((prevState) => ({
                                    ...prevState,
                                    categoryOptions: setValuesForDropdown(res?.data?.categoryType, "name", "id"),
                                    accountTypeOptions: setValuesForDropdown(accountTypeRes?.data?.accountType, "name", "id"),
                                    accountCategories: res?.data?.categoryType,
                                    CRMDropdownValues: setValuesForCRMDropdown(CRMData, "firstName", "id"),
                                    isShowLoaderMaster: false,
                                }))
                                setPage(isEmpty(FTReportFilter.FTReportPage) ? 0 : FTReportFilter.FTReportPage);
                                setRowsPerPage(isEmpty(FTReportFilter.FTReportRowsPerPage) ? 50 : FTReportFilter.FTReportRowsPerPage);
                            }
                        })

                    } else {
                        setFTReportListForManagementState((prevState) => ({
                            ...prevState,
                            isShowLoaderMaster: false,
                        }))
                    }
                })
            }
        })
    }

    const getFTReportDetails = () => {
        const {skipCount, limitCount} = props;

        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }))

        let payload = {
            "skipCount": skipCount,
            "limitCount": limitCount,
            "filters": generateFilter(FTReportFilter)
        }

        getTxnReportDetails(payload, true).then((res) => {
            if (res?.success) {

                setFTReportListForManagementState((prevState) => ({
                    ...prevState,
                    listingCount: res?.data?.count,
                    FTReportListDetails: res?.data,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    isShowLoader: false,
                }))
                setPage(isEmpty(FTReportFilter.FTReportPage) ? 0 : FTReportFilter.FTReportPage);
                setRowsPerPage(isEmpty(FTReportFilter.FTReportRowsPerPage) ? 50 : FTReportFilter.FTReportRowsPerPage);
            } else {
                setFTReportListForManagementState((prevState) => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }))
            }
        })
    }

    const handleActionClicked = (isActionClicked, id) => {
        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            selectedId: id
        }))
    }

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = FTReportListForManagementState;

        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setFTReportSkipCount(skipCount);
        setFTReportLimitCount(limitCount);
        setFTReportPageCount(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setFTReportListForManagementState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setRowsPerPage(parseInt(event.target.value, 10));

        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            skipCount: 0,
            limitCount: parseInt(event.target.value, 10),
            pageCount: 0,
        }))

        setFTReportRowsPerPage(parseInt(event.target.value, 10));
        setFTReportLimitCount(parseInt(event.target.value, 10));
        setFTReportPageCount(0);
        setFTReportSkipCount(0);
    };

    const handleToggleMobileFilter = (value) => {
        setFTReportListForManagementState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const handleStatusModalOpen = () => {
        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isStatusModalOpen: true
        }))
    }

    const handleStatusModalClose = () => {
        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isStatusModalOpen: false
        }))
    }

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setFTReportListForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setFTReportKeywordValue(value);
        }

        if (name === 'CRMId') {
            setFTReportListForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    CRMId: [value]
                }
            }));

            setFTReportCRMId([value]);
        }
    };

    const getCRM = (CRMId, CRMOptions) => {
        if (isEmpty(CRMId) || isEmpty(CRMOptions)) {
            return '-'
        }
        const CRM = CRMOptions.find(crm => crm.value === CRMId.toString());
        return CRM ? CRM.label : '-';
    };

    const getCategory = (selectedCategory, accountCategories) => {
        if (isEmpty(selectedCategory) || isEmpty(accountCategories)) {
            return ''
        }
        if (selectedCategory === 'CORPORATE') {
            return 'Null'
        }
        const category = accountCategories.find(category => category.id === selectedCategory);
        return category ? ' - ' + category.name : '';
    };

    const getAccountType = (value) => {
        const {accountTypeOptions} = FTReportListForManagementState;
        const accountType = accountTypeOptions.find(item => item?.value === value);
        return accountType?.label || '';
    };

    const generateFilter = (filter) => {

        let filterObject = {};

        if (!isEmpty(filter.FTReportKeyword)) {
            filterObject.keyword = filter.FTReportKeyword;
        }
        if (!isEmpty(filter.FTReportCRMId)) {
            if (filter.FTReportCRMId[0] === "ALL") {
                delete filterObject.FTReportCRMId
            } else if(filter.FTReportCRMId[0] === "UNASSIGNED") {
                filterObject.CRMId = 'UNASSIGNED';
            }
            else {
                filterObject.CRMId = filter.FTReportCRMId[0];
            }
        }

        return filterObject;
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const setValuesForCRMDropdown = (CRMData, labelToken, valueToken) => {

        let list = [{label: "All", value: 'ALL'},{label: "Unassigned", value: 'UNASSIGNED'}]
        if (isEmpty(CRMData)) {
            return;
        }
        CRMData.map((item) => {
            list.push({label: item?.[labelToken.toString()] + " " + item?.lastName, value: item?.[valueToken].toString()})
        });

        return list;
    };

    const resetPagination = () => {

        setFTReportRowsPerPage(10);
        setFTReportLimitCount(10);
        setFTReportPageCount(0);
        setFTReportSkipCount(0);
    };

    const resetFTReportListing = () => {
        resetFTReportFilters();
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = FTReportListForManagementState;

        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    return (
        <FTReportListForManagementView
            {...props}
            {...FTReportListForManagementState}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleToggleMobileFilter={handleToggleMobileFilter}
            onChangeFilter={onChangeFilter}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleActionClicked={handleActionClicked}
            handleStatusModalOpen={handleStatusModalOpen}
            handleStatusModalClose={handleStatusModalClose}
            resetFTReportListing={resetFTReportListing}
            getCategory={getCategory}
            getAccountType={getAccountType}
            getCRM={getCRM}
            getFTReportDetails={getFTReportDetails}
        />
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    profile: state.userState.profile,
    FTReportFilter: state.FTReportState.FTReportFilter,
});

export default connect(mapStateToProps, {})(FTReportListForManagement);
