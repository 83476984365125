import React, {useEffect, useState} from 'react';

import {isEmpty} from "../../../../../utils/validations";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import {getChequeDepositModes, getFTStatuses, getPaymentModes, getTransactionDetails} from "../../../FTAction";
import {getAccountTypeOptions, getClientRequestById} from "../../../../../clientRequest/ClientRequestsActions";

import {connect} from "react-redux";

import FTDetailsForCRMView from "./FTDetailsForCRMView";
import {getBanks} from "../../../../../onboarding/OnboardingActions";

const FTDetailsForCRM = (props) => {
    const [FTDetailsState, setFTDetailsState] = useState({

        ticketNumber: '',
        accountCategory: '',
        accountHolders: '',
        status: "",
        accountType: '',
        IINNumber: '',
        paymentDetails: [],
        investmentDetails: [],
        accountDetails: [],
        clientRequestId: '',
        txnRequestId: '',
        isShowLoader: false,
        category: '',
        advisoryUsers: [],
        FTStatuses: [],
        chequeDepositModes: [],
        accountTypeOptions: [],
        paymentModes: [],
        details: [],
        corporateAccountDetails: [],
        banksData: [],
        createdBy: '',
        comments: '',
        createdAt: '',
        responseError: '',
        UIState: '',
        txnStatus: '',
        rejectComment: '',
        txnStage: '',
        selectedId: '',
        accountId: '',
        paymentId: '',
        isDiscardTransactionModalOpen: false,
        isSendToManagementModalOpen: false,
        isEditPaymentModalOpen: false,
        isMoveToDraftModalOpen: false,
        isResendModalOpen: false,
        isManagementUser: false,
        isCopyTransactionModalOpen: false,
        isOpenNSEErrorModal : false,
        txnNSEErrors: [],
        updatedAt: ''
    });

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        getDetails();
    }, []);

    const getClientRequestId = () => {
        return props.match?.params?.clientRequest
    };

    const getTransactionRequestId = () => {
        return props.match?.params?.transactionId
    };

    const getAdvisoryUserName = (value) => {
        const {advisoryUsers} = FTDetailsState;
        if (isEmpty(value)) {
            return '-'
        }
        const user = advisoryUsers.find(item => item.id === value);
        return user ? user.firstName + " " + user.lastName : "-";
    };

    const getDetails = () => {
        const {profile} = props;
        const clientRequestId = getClientRequestId();
        const txnRequestId = getTransactionRequestId();
        setFTDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getFTStatuses().then(FTStatusRes => {
            if (FTStatusRes?.success) {
                let FTStatuses = FTStatusRes?.data;

                getPaymentModes().then(paymentModeRes => {
                    if (paymentModeRes?.success) {
                        let paymentModesData = paymentModeRes.data?.paymentMechanisms;

                        getBanks()
                            .then(banksRes => {
                                if (banksRes?.success) {
                                    let banksData = banksRes?.data?.banks;

                                    getChequeDepositModes().then(chequeDepositModeRes => {
                                        if (chequeDepositModeRes?.success) {

                                            getAccountTypeOptions()
                                                .then(accountTypeRes => {
                                                    if (accountTypeRes?.success) {

                                                        getClientRequestById(clientRequestId).then(res => {
                                                            if (res?.success) {
                                                                let requestData = res.data;

                                                                getTransactionDetails(txnRequestId).then(transactionsRes => {
                                                                    if (transactionsRes?.success) {
                                                                        let transactionsData = transactionsRes.data;

                                                                        setFTDetailsState(prevState => ({
                                                                            ...prevState,
                                                                            paymentDetails: transactionsData?.paymentDetails,
                                                                            investmentDetails: transactionsData?.funds,
                                                                            txnStatus: transactionsData?.txnRequestStatus?.status,
                                                                            txnStage: transactionsData?.txnRequestStatus?.stage,
                                                                            txnData: transactionsData,
                                                                            txnNSEErrors:isEmpty(transactionsData?.txnNseRequest?.txnNSEErrors) ? [] : transactionsData?.txnNseRequest?.txnNSEErrors,
                                                                            banksData: banksData,
                                                                            paymentModes: paymentModesData,
                                                                            rejectComment: isEmpty(transactionsData?.txnRequestComments)? "" : transactionsData?.txnRequestComments[0]?.comments,
                                                                            clientRequestId: clientRequestId,
                                                                            txnRequestId: txnRequestId,
                                                                            accountTypeOptions: accountTypeRes.data?.accountType,
                                                                            details: requestData,
                                                                            corporateAccountDetails: isEmpty(requestData?.accountDetails)? [] : requestData?.accountDetails[0]?.corporate,
                                                                            accountHolders: getFormattedData('accountHolders', requestData?.accountDetails),
                                                                            accountType: getFormattedData('accountType', requestData?.accountDetails, accountTypeRes.data?.accountType),
                                                                            IINNumber: getFormattedData('IINNumber', requestData?.accountDetails),
                                                                            category: getFormattedData('category', requestData?.accountDetails),
                                                                            chequeDepositModes: setValuesForDropdown(chequeDepositModeRes.data?.chequeDepositModes, 'name', 'id'),
                                                                            accountDetails: requestData?.accountDetails[0]?.accountHolders,
                                                                            ticketNumber: requestData?.ticketNumber,
                                                                            isManagementUser: profile?.isManagement,
                                                                            accountId: requestData?.accountId,
                                                                            paymentId: transactionsData?.paymentDetails?.id,
                                                                            FTStatuses: FTStatuses,
                                                                            isShowLoader: false,
                                                                            UIState: APP_CONST.CONTENT_AVAILABLE,
                                                                            responseError: '',
                                                                            updatedAt: transactionsData?.txnNseRequest?.updatedAt

                                                                        }));

                                                                    } else {
                                                                        setFTDetailsState(prevState => ({
                                                                            ...prevState,
                                                                            isShowLoader: false,
                                                                            UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                                                            responseError: transactionsRes.__error
                                                                        }));
                                                                    }
                                                                })
                                                            } else {
                                                                setFTDetailsState(prevState => ({
                                                                    ...prevState,
                                                                    isShowLoader: false,
                                                                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                                                    responseError: res?.__error
                                                                }));
                                                            }
                                                        });
                                                    }
                                                })
                                        }
                                    })

                                }})

                    }
                })
            }
        })
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return [];
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    function capitalizeFirstLetters(name) {
        return name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const getFormattedData = (name, accountDetails, masterData) => {
        const {isMobileView} = props
        if (name === 'accountHolders') {
            if(isEmpty(accountDetails?.accountHolders)){
                return "";
            }
            return accountDetails.flatMap(account =>

                account.accountHolders.map(holder => capitalizeFirstLetters(holder.fullName))
            );
        }
        if (name === 'accountType') {
            const accountType = accountDetails.map(account => {
                    return account.accountType
                }
            );
            let type = getAccountType(accountType[0], masterData)
            if (isMobileView) {
                return type + ' A/c';
            } else {
                return type + ' Account';
            }
        }
        if (name === 'IINNumber') {
            return accountDetails.flatMap(account => {
                    return account.IINNumber
                }
            );
        }
        if (name === 'category') {
            return accountDetails.flatMap(account => {
                    if (account.category === 'HNI') {
                        return account.category;
                    } else if (account.category === 'UNASSIGNED') {
                        return capitalizeFirstLetters(account.category);
                    } else if (account.category === 'RETAIL') {
                        return capitalizeFirstLetters(account.category);
                    } else if (account.category === 'PREMIUM_RETAIL') {
                        return 'Premium Retail';
                    } else if (account.category === 'ULTRA_HNI') {
                        return 'Ultra HNI';
                    }else{
                        return '-';
                    }
                }
            );
        }

    };

    const getAccountType = (accountType, masterData) =>{
        if (isEmpty(accountType) || isEmpty(masterData)) {
            return;
        }

        let label = masterData.find((item) => {
            return item?.id === accountType;
        });

        return isEmpty(label)? "" : label?.name;
    };

    const handleOpenDiscardTransactionModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isDiscardTransactionModalOpen: true
        }));
    };

    const handleCloseDiscardTransactionModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isDiscardTransactionModalOpen: false
        }));
    };

    const handleOpenSendToManagementModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isSendToManagementModalOpen: true
        }));
    };

    const handleCloseSendToManagementModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isSendToManagementModalOpen: false
        }));
    };

    const handleOpenEditPaymentModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isEditPaymentModalOpen: true
        }));
    };

    const handleCloseEditPaymentModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isEditPaymentModalOpen: false
        }));
    };

    const handleOpenMoveToDraftModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isMoveToDraftModalOpen: true
        }));
    };

    const handleCloseMoveToDraftModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isMoveToDraftModalOpen: false
        }));
    };

    const handleOpenResendModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isResendModalOpen: true
        }));
    };

    const handleCloseResendModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isResendModalOpen: false
        }));
    };

    const handleOpenCopyTransactionModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isCopyTransactionModalOpen: true
        }));
    };

    const handleCloseCopyTransactionModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isCopyTransactionModalOpen: false
        }));
    };

    const handleAddOrEditTransactionDetails = (action) => {
        const{clientRequestId, txnRequestId} = FTDetailsState;
        if(action === "ADD"){
            props.history.replace('/client-requests/' + clientRequestId + '/lumpsum-transaction/' + txnRequestId + '/select-portfolio/purchase-transaction',
                {mode: 'ADD', isFrom : 'addScheme'});
        }else if(action === "EDIT"){
            props.history.replace("/client-requests/"+clientRequestId+"/lumpsum-transaction/"+txnRequestId+"/select-portfolio/purchase-transaction",
                {mode: 'EDIT'})
        }
    };

    const getDepositMode = (selectedId) => {
        if(isEmpty(selectedId)){
            return "-";
        }
        let depositMode = '';
        const {chequeDepositModes} = FTDetailsState;
        depositMode = chequeDepositModes?.find((item) => item?.value === selectedId);
        return depositMode?.label;
    };

    const onSaveSuccess = () => {
        getDetails();
    };

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const getAccountHolderDetails = (accountDetails, accountType) => {
        if(isEmpty(accountDetails)){
            return;
        }
        if (accountType === "MINOR") {
            return accountDetails?.filter(holder => holder?.accountHolderType !== "GUARDIAN");
        }
        return accountDetails;
    };

    const getBankName = (id) => {
        const {banksData} = FTDetailsState;
        if (isEmpty(banksData) || isEmpty(id)) {
            return '-'
        }
        let bankObj = banksData.find(item => item?.code === id);
        return isEmpty(bankObj?.description) ? '' : bankObj?.description
    };

    const getPaymentMode = (id) => {
        const {paymentModes} = FTDetailsState;
        if (isEmpty(paymentModes) || isEmpty(id)) {
            return '-'
        }
        let paymentObj = paymentModes.find(item => item?.code === id);
        return isEmpty(paymentObj?.description) ? '' : paymentObj?.description
    };

    const handleCloseNSEErrorsModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isOpenNSEErrorModal: false
        }));
    };

    const handleOpenNSEErrorsModal = () => {
        setFTDetailsState((prevState) => ({
            ...prevState,
            isOpenNSEErrorModal: true
        }));
    };

    return (
        <FTDetailsForCRMView
            {...props}
            {...FTDetailsState}
            anchorEl={anchorEl}
            getDetails={getDetails}
            onSaveSuccess={onSaveSuccess}
            getAdvisoryUserName={getAdvisoryUserName}
            handleOpenDiscardTransactionModal={handleOpenDiscardTransactionModal}
            handleCloseDiscardTransactionModal={handleCloseDiscardTransactionModal}
            handleOpenSendToManagementModal={handleOpenSendToManagementModal}
            handleCloseSendToManagementModal={handleCloseSendToManagementModal}
            handleOpenEditPaymentModal={handleOpenEditPaymentModal}
            handleCloseEditPaymentModal={handleCloseEditPaymentModal}
            handleAddOrEditTransactionDetails={handleAddOrEditTransactionDetails}
            handleItemOpen={handleItemOpen}
            handleItemClose={handleItemClose}
            handleOpenMoveToDraftModal={handleOpenMoveToDraftModal}
            handleCloseMoveToDraftModal={handleCloseMoveToDraftModal}
            getDepositMode={getDepositMode}
            getBankName={getBankName}
            getAccountHolderDetails={getAccountHolderDetails}
            getPaymentMode={getPaymentMode}
            handleOpenResendModal={handleOpenResendModal}
            handleCloseResendModal={handleCloseResendModal}
            handleOpenCopyTransactionModal={handleOpenCopyTransactionModal}
            handleCloseCopyTransactionModal={handleCloseCopyTransactionModal}
            handleCloseNSEErrorsModal={handleCloseNSEErrorsModal}
            handleOpenNSEErrorsModal={handleOpenNSEErrorsModal}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(FTDetailsForCRM);
