import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'FTReportReducer',
    initialState: {
        FTReportFilter: {
            FTReportKeyword: '',
            FTReportCRMId: ['ALL'],
            FTReportSkipCount: 0,
            FTReportLimitCount: 50,
            FTReportPage:0,
            FTReportRowsPerPage: 50
        },
        CRMDetails: []
    },
    reducers: {
        setFTReportSearchKeywordValue (state,action) {
            state.FTReportFilter.FTReportKeyword = action.payload;
        },

        setFTReportCRMIdValue(state, action) {
            state.FTReportFilter.FTReportCRMId = action.payload;
        },

        setFTReportSkipCountValue(state,action){
            state.FTReportFilter.FTReportSkipCount = action.payload;
        },

        setFTReportLimitCountValue(state,action){
            state.FTReportFilter.FTReportLimitCount = action.payload;
        },

        setFTReportPageValue(state,action){
            state.FTReportFilter.FTReportPage = action.payload;
        },

        setFTReportRowsPerPageValue(state,action){
            state.FTReportFilter.FTReportRowsPerPage = action.payload;
        },

        resetFTReportAllFilters (state) {
            state.FTReportFilter = {
                ...state.FTReportFilter,
                FTReportKeyword: '',
                FTReportCRMId: ['ALL'],
                FTReportSkipCount: 0,
                FTReportLimitCount: 50,
                FTReportPage:0,
                FTReportRowsPerPage: 50
            };
        },
        setFTReportCRMDetails(state,action) {
            state.CRMDetails = action.payload;
        },
    }
});

export const {setFTReportSearchKeywordValue, resetFTReportAllFilters, setFTReportRowsPerPageValue, setFTReportPageValue,
    setFTReportLimitCountValue,setFTReportSkipCountValue, setFTReportCRMDetails, setFTReportCRMIdValue} = appSlice.actions;

export default appSlice.reducer